import React, { useState, useEffect, useRef } from "react";
import "./LandingPage.css";
import TwiImg1 from "../../assets/twiImg1.png"
import TwiImg2 from "../../assets/twiImg2.png"
import TwiImg3 from "../../assets/twiImg3.png"
import TwiImg4 from "../../assets/twiImg4.png"
import TwiImg5 from "../../assets/twiImg5.png"
import TwiImg6 from "../../assets/twiImg6.png"

const TravelWithIndie = () => {

    return (
        <div className="travelWithIndieMainContainer">
            <div className="travelWithIndieMainHead">Why travel with iNDiE?</div>
            <div className="travelWithIndieInnerContainer">
                <div className="travelWithIndieItemContainer">
                    <div>
                        <img src={TwiImg1} alt="" />
                        <div>
                            <span className="travelWithIndieItemName" >BOOK</span>
                            <span className="travelWithIndieItemDes" >Pre-arranged package of hotels and attractions.</span>
                        </div>
                    </div>
                    <div>
                        <img src={TwiImg2} alt="" />
                        <div>
                            <span className="travelWithIndieItemName">ITINERARY</span>
                            <span className="travelWithIndieItemDes">Map, Points of Interest, day-by-day</span>
                        </div>
                    </div>
                    <div>
                        <img src={TwiImg3} alt="" />
                        <div>
                            <span className="travelWithIndieItemName">NAVIGATE</span>
                            <span className="travelWithIndieItemDes">Comprehensive navigation app, even offline</span>
                        </div>
                    </div>
                </div>
                <div className="travelWithIndieItemContainer">
                    <div>
                        <img src={TwiImg4} alt="" />
                        <div>
                            <span className="travelWithIndieItemName">TIPS</span>
                            <span className="travelWithIndieItemDes">Real-time location-based tips and recommendations</span>
                        </div>
                    </div>
                    <div>
                        <img src={TwiImg5} alt="" />
                        <div>
                            <span className="travelWithIndieItemName">SUPPORT</span>
                            <span className="travelWithIndieItemDes">On-the-go assistance from our local expert</span>
                        </div>
                    </div>
                    <div>
                        <img src={TwiImg6} alt="" />
                        <div>
                            <span className="travelWithIndieItemName">Hiking Trails</span>
                            <span className="travelWithIndieItemDes">Explore and appreciate the natural beauty of the places you visit</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TravelWithIndie;
