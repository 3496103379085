
  const BASE_URL_IMG = "https://planmytrip-assets.s3.amazonaws.com/assets/"

  export const countries: any = [
    {
      label: "Greece",
      season: "End of March through May and September through November",
      image: BASE_URL_IMG + "resources/countries/Greece_small.jpg",
      flagImage: BASE_URL_IMG + "resources/countries/Greece.png",
      status: "Take off",
      selectable: true,
      countryCode: "GR",
    },
    {
      label: "Portugal",
      season: "April through June and September through November",
      image: BASE_URL_IMG + "resources/countries/Portugal_small.jpg",
      flagImage: BASE_URL_IMG + "resources/countries/Portugal.png",
      status: "Take off",
      selectable: true,
      countryCode: "PT",
    },
    {
      label: "Norway",
      season: "May through September",
      image: BASE_URL_IMG + "resources/countries/Norway_small.jpg",
      flagImage: BASE_URL_IMG + "resources/countries/Norway.png",
      status: "Take off",
      selectable: true,
      countryCode: "NO",
    },
    {
      label: "Georgia",
      season: "May through October",
      image: BASE_URL_IMG + "resources/countries/Georgia_small.jpg",
      flagImage: BASE_URL_IMG + "resources/countries/Georgia.png",
      status: "Take off",
      selectable: true,
      countryCode: "GE",
    },
    {
      label: "Mexico",
      season: "November through April",
      image: BASE_URL_IMG + "resources/countries/mexico.png",
      flagImage: BASE_URL_IMG + "resources/countries/MEXICO.png",
      status: "Take off",
      selectable: true,
      countryCode: "MX",
    },
    {
      label: "Montenegro",
      season: "May through October",
      image: BASE_URL_IMG + "resources/countries/Montenegro_small.jpg",
      flagImage: BASE_URL_IMG + "resources/countries/Montenegro.png",
      status: "Take off",
      selectable: true,
      countryCode: "ME",
    },
    {
      label: "Denmark",
      season: "May through September",
      image: BASE_URL_IMG + "resources/countries/denmark.png",
      flagImage: BASE_URL_IMG + "resources/countries/DENMARK.png",
      status: "Take off",
      selectable: true,
      countryCode: "DK",
    },
    {
      label: "Poland",
      season: "May through October",
      image: BASE_URL_IMG + "resources/countries/poland.png",
      flagImage: BASE_URL_IMG + "resources/countries/Poland.png",
      status: "Take off",
      selectable: true,
      countryCode: "PL",
    },
    {
      label: "Costa Rica",
      season: "December through April",
      image: BASE_URL_IMG + "resources/countries/CostaRica_small.jpg",
      flagImage: BASE_URL_IMG + "resources/countries/Costa Rica.png",
      status: "Take off",
      selectable: true,
      countryCode: "CR",
    },
    {
      label: "Iceland",
      season: "Mid-May through Mid-September",
      image: BASE_URL_IMG + "resources/countries/Iceland_small.jpg",
      flagImage: BASE_URL_IMG + "resources/countries/Iceland.png",
      status: "Take off",
      selectable: true,
      countryCode: "IS",
    },
    {
      label: "Morocco",
      season: "Mid-March through May and September through Mid-November",
      image: BASE_URL_IMG + "resources/countries/morocco.png",
      flagImage: BASE_URL_IMG + "resources/countries/MOROCCO.png",
      status: "Take off",
      selectable: true,
      countryCode: "MA",
    },
    {
      label: "Israel",
      season: "End of March through November",
      image: BASE_URL_IMG + "resources/countries/Israel_small.jpg",
      flagImage: BASE_URL_IMG + "resources/countries/Israel.png",
      status: "Take off",
      selectable: true,
      countryCode: "IL",
    },
    {
      label: "Turkey",
      season: "End of March through October",
      image: BASE_URL_IMG + "resources/countries/new_Turkey.jpeg",
      flagImage: BASE_URL_IMG + "resources/countries/Turkey.png",
      status: "Take off",
      selectable: true,
      countryCode: "TR",
    },
    {
      label: "India",
      season: "November through March",
      image: BASE_URL_IMG + "resources/countries/India_small.jpg",
      flagImage: BASE_URL_IMG + "resources/countries/India.png",
      status: "Take off",
      selectable: true,
      countryCode: "IN",
    },
  /*   {
      label: "Ecuador",
      season: "June through December",
      image: BASE_URL_IMG + "resources/countries/ecuador_small.jpg",
      status: "Cooming Soon",
      selectable: false,
      countryCode: "EC",
    }, */
    {
      label: "South Africa",
      season: "May through September",
      image: BASE_URL_IMG + "resources/countries/SouthAfrica_small.jpg",
      flagImage: BASE_URL_IMG + "resources/countries/South Africa.png",
      status: "Take off",
      selectable: true,
      countryCode: "ZA",
    },
  /*   {
      label: "Lithuania",
      season: "Mid-May through Mid-September",
      image: BASE_URL_IMG + "resources/countries/new_Lithuania.jpeg",
      status: "Cooming Soon",
      selectable: false,
      countryCode: "LT",
    },
    {
      label: "Ireland",
      season: "End of April through September",
      image: BASE_URL_IMG + "resources/countries/new_ireland.jpeg",
      status: "Cooming Soon",
      selectable: false,
      countryCode: "IE",
    }, */
    {
      label: "Spain",
      season: "Mid-April through October",
      image: BASE_URL_IMG + "resources/countries/Spain.jpg",
      flagImage: BASE_URL_IMG + "resources/countries/SpainFlag.png",
      status: "Cooming Soon",
      selectable: false,
      countryCode: "ES",
    },
  
    // {
    //   label: 'South Korea',
    //   season: 'April through October',
    //   image: BASE_URL_IMG + 'resources/countries/SouthKorea_small.jpg',
    //   status: 'Cooming Soon',
    //   selectable: false,
    //   countryCode: 'KR'
    // },
    // {
    //   label: 'Argentina',
    //   season: 'April through October',
    //   image: BASE_URL_IMG + 'resources/countries/Argentina_small.jpg',
    //   status: 'Cooming Soon',
    //   selectable: false,
    //   countryCode: 'AR'
    // },
    // {
    //   label: 'Chile',
    //   season: 'April through October',
    //   image: BASE_URL_IMG + 'resources/countries/Chile_small.jpg',
    //   status: 'Cooming Soon',
    //   selectable: false,
    //   countryCode: 'CL'
    // },
    //   {
    //     label: 'Romania',
    //     season: 'April through October',
    //   image: BASE_URL_IMG + 'resources/countries/Romania.jpg',
    //   status: 'Cooming Soon',
    //   selectable: false,
    //   countryCode: 'RO'
    // },
  ];
  