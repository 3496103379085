import React, { useState, useEffect, useRef } from "react";
import "./Register.css";
import { useNavigate, useLocation } from 'react-router-dom';
import VideoPage from "../Components/LandingFolder/VideoSection";
import BlackEnvelopeIcon from "../assets/blackEnvelopeIcon.png"
import FbBlackIcon from "../assets/fbBlackIcon.png"
import BlackWhatsappIcon from "../assets/blackWhatsappIcon.png"
import DropDownArrow from "../assets/dropDownArrow.png"
import GallerySection from "../Components/Destinations/GallerySection";
import { BASE_URL, get, post } from "../services/Calls";
import AuthLoader from '../assets/authLoader.gif'
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { ToastContainer, toast } from 'react-toastify';
import { useSpring, animated } from 'react-spring';
import Header from "./Header";
import Footer from "./Footer";
import Loader from "./Loader";

const Register = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [errorMsg, setErrorMsg] = useState("")
    const [loader, setLoader] = useState(false)
    const [countryData, setCountryData]: any = useState([])
    const [tripData, setTripData]: any = useState([])
    const [country, setCountry]: any = useState({})
    const [trip, setTrip]: any = useState({})
    const [showCountrySelect, setShowCountrySelect] = useState(false);
    const [showTripSelect, setShowTripSelect] = useState(false);

    // const [scrollY, setScrollY] = useState(0);
    const [mobileView, setMobileView] = useState(window.innerWidth <= 768);

    useEffect(() => {
      function updateSize() {
        if (window.innerWidth <= 768) setMobileView(true);
        else setMobileView(false);
      }
      window.addEventListener("resize", updateSize);
    }, []);

  
    useEffect(() => {
      function updateScroll() {
        // setScrollY(window.scrollY);
        if(window.scrollY > 300){
            setIsAnimated(true);
        }
        else{
          setIsAnimated(false);
        }
      }
      window.addEventListener('scroll', updateScroll);

    }, []); 


    const [isAnimated, setIsAnimated] = useState(false);

  // Define the animation properties using useSpring
  const animation = useSpring({
    opacity: isAnimated ? 1 : 0,
    transform: `scale(${isAnimated ? 1 : 0.5})`,
  });



    useEffect(() => {
        getCountries()
        getTrips()
        window.scrollTo(0, 0);
    }, []);
    const getCountries = async () => {
        try{
        const response = await get(BASE_URL + '/countries?order=ASC')
        if (response && response.length > 0) {
            setCountryData(response)
        }
    }
    catch(ex){
        //alert(ex)
console.log('error',ex)
    }
    }
    const getTrips = async () => {
        try{
        const response = await get(BASE_URL + '/trips?order=ASC')
        if(response && response.results && response.results.length>0 ){
          setTripData(response.results)
        }
    }
    catch(ex){
        //alert(ex)
console.log('error',ex)
    }
    }


    const handleSubmit = async (event: any) => {

      event.preventDefault();
      const formData = event.currentTarget.elements;
  
      try {
        if (formData[0].value === '') {
          setErrorMsg("Name can't be empty.")
          return;
        }
        if (formData[1].value === '') {
          setErrorMsg("Email can't be empty.")
          return;
        }
        if (formData[1].value !== "") {
          let pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
          if (!pattern.test(formData[1].value)) {
            setErrorMsg("Invalid email format.");
            return;
          }
        }
        if (formData[2].value === '') {
          setErrorMsg("Phone can't be empty.")
          return;
        }
  
        let tempEmail = formData[1].value
        tempEmail = tempEmail.toLowerCase();
        const data = {
          "name": formData[0].value,
          "email": tempEmail,
          "phone": formData[2].value,
          "destination": country.id,
          "trip_id": trip.id,
          "comment": formData[3].value
        };
        setLoader(true)
        const response = await post(BASE_URL + '/contact/register', data)
        if (response && response.data && response.data.status == 'ok') {
          notify()
          setLoader(false);
          navigate('/')
        }
      } catch (ex) {
        setLoader(false);
        // setErrorMsg(ex.response.statusText);
      }
  
    };

    const notify = () => {
      toast.success('You are register successfully.', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
  };

    return (
        <div>
            <Header/>
            <VideoPage pageFrom={'register'} countryname={""} />
            <div className="destinationPathRow" id='registerPageId'>
                <div className="destinationPaginationDiv">
                    <span className="destinationPaginationHome" onClick={() => {navigate('/')}} >Home</span>
                    <span className="destinationPaginationBorder"></span>
                    <span className="destinationPaginationCountry">Register</span>
                </div>
                <div className="destinationSocialIconDiv">
                    <img className="destinationSocialFbIcon" src={FbBlackIcon} />
                    <img className="destinationSocialMailIcon" src={BlackEnvelopeIcon} />
                    <img className="destinationSocialWhatsAppIcon" src={BlackWhatsappIcon} />
                </div>
            </div>

<animated.div /* className={`${scrollY>200?"wow bounceInUp":""}`} */ style={{...animation}} /* className={`${"wow bounceInUp"}`} */>
            <div className="registerFormDiv">
                <form onSubmit={handleSubmit} >
                    <div className='registerInputCss'>
                        <input
                            type="text"
                            name="name"
                            placeholder='Name'
                            onFocus={() => { setErrorMsg('') }}
                        />
                    </div>
                    <div className='registerInputCss'>
                        <input
                            type="email"
                            name="email"
                            placeholder='Email'
                            onFocus={() => { setErrorMsg('') }}
                        />
                    </div>

                    <div className='registerInputCss'>
                        <input
                            type="number"
                            name="phone"
                            placeholder='Phone'
                            onFocus={() => { setErrorMsg('') }}
                        />
                    </div>

                    <div className='registerInputCss' style={{ cursor: 'pointer', justifyContent: 'flex-start', alignItems: 'flex-start' }} onClick={() => { setShowCountrySelect(!showCountrySelect); setErrorMsg("") }}>

                        <div className='InputBoxCss' style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                            {country && country.id ?
                                <span style={{ color: '#000', fontSize:mobileView?"3.5vw":'1.06vw' }}>{country.name}</span>
                                :
                                <span style={{ color: 'gray', fontSize:mobileView?"3.5vw":'1.06vw' }}>Destination</span>
                            }
                            <img src={DropDownArrow} style={{ width: mobileView?"3.5vw":'1vw', height: mobileView?"3.5vw":'1vw', transform: showCountrySelect ? "rotate(270deg)" : 'rotate(90deg)' }} />
                        </div>

                    </div>
                    {showCountrySelect &&
                        <ClickAwayListener onClickAway={() => { setShowCountrySelect(false) }}>
                            <div className="dropDownDiv">
                                {countryData && countryData.length > 0 && countryData.map((item: any, index: any) => (
                                    <div className="countryDropDownItemDiv" onClick={() => { setCountry(item); setShowCountrySelect(false) }}>
                                        {item.name}
                                    </div>
                                ))}
                            </div>
                        </ClickAwayListener>
                    }

                    <div className='registerInputCss' style={{ cursor: 'pointer', justifyContent: 'flex-start', alignItems: 'flex-start' }} onClick={() => { setShowTripSelect(!showTripSelect); setErrorMsg("") }}>

                        <div className='InputBoxCss' style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                            {trip && trip.id ?
                                <span style={{ color: '#000', fontSize:mobileView?"3.5vw":'1.06vw' }}>{trip.name}</span>
                                :
                                <span style={{ color: 'gray', fontSize:mobileView?"3.5vw":'1.06vw' }}>Trip</span>
                            }
                            <img src={DropDownArrow} style={{ width: mobileView?"3.5vw":'1vw', height: mobileView?"3.5vw":'1vw', transform: showTripSelect ? "rotate(270deg)" : 'rotate(90deg)' }} />
                        </div>

                    </div>
                    {showTripSelect &&
                        <ClickAwayListener onClickAway={() => { setShowTripSelect(false) }}>
                            <div className="dropDownDiv">
                                {tripData && tripData.length > 0 && tripData.map((item: any, index: any) => (
                                    <div className="countryDropDownItemDiv" onClick={() => { setTrip(item); setShowTripSelect(false) }}>
                                        {item.name}
                                    </div>
                                ))}
                            </div>
                        </ClickAwayListener>
                    }

<textarea aria-invalid="false" id="txtDescription" rows={4} placeholder="comment" className="registerInputCss" style={{ width: '100%' }} onFocus={() => { setErrorMsg('') }} />

                    {errorMsg != '' && (
                        <div
                            style={{
                                textAlign: 'center',
                                color: 'red',
                                fontSize: mobileView?"3.5vw":'1vw',
                            }}
                        >
                            {errorMsg}
                        </div>
                    )}
                    <div className='registerPageBtnUpperDiv' >
                        <button type="submit" className='registerPageBtn'>Register</button>
                    </div>
                </form>
            </div>
            </animated.div>
            <div style={{ height: '6.625vw' }}></div>
            {loader &&
                <Loader/>
            }
            <Footer/>
        </div>
    )
}
export default Register;
