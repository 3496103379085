import React, { useState, useEffect, useRef } from "react";
import "./LandingPage.css";
import IndieGuideImg1 from "../../assets/indieGuideImg1.png"
import IndieGuideImg2 from "../../assets/indieGuideImg2.png"
import IndieGuideImg3 from "../../assets/indieGuideImg3.png"
import IndieGuideImg4 from "../../assets/indieGuideImg4.png"

const IndieGuide = () => {

    return (
        <div className="indieGuideMainContainer">
            <div className="indieGuideBgImg">
                <div className="indieGuideMainHead">
                    What's in the iNDiE Guide?
                </div>
                <div className="indieGuideInnerDiv">
                    <div className="indieGuideInnerItemDiv">
                        <img src={IndieGuideImg1} />
                        <div>
                            Personalized
                            <br />
                            Itinerary
                        </div>
                    </div>
                    <div className="indieGuideInnerItemDiv">
                        <img src={IndieGuideImg2} />
                        <div>
                            Navigation
                            <br />
                            Tool
                        </div>
                    </div>
                    <div className="indieGuideInnerItemDiv">
                        <img src={IndieGuideImg3} />
                        <div>
                            Tips &
                            <br />
                            Information
                        </div>
                    </div>
                    <div className="indieGuideInnerItemDiv">
                        <img src={IndieGuideImg4} />
                        <div>
                            On-trip
                            <br />
                            Support
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default IndieGuide;
