import React, { useState, useEffect, useRef } from "react";
import "./DestinationsPage.css";
import Gs1 from "../../assets/gs1.png"
import Gs2 from "../../assets/gs2.png"
import Gs3 from "../../assets/gs3.png"
import Gs4 from "../../assets/gs4.png"
import Gs5 from "../../assets/gs5.png"
import { useNavigate } from 'react-router-dom';
import ImageViewer from "./ImageViewer";
import Dialog from '@mui/material/Dialog'
import ClickAwayListener from '@mui/material/ClickAwayListener'

const GallerySection = () => {
    const navigate = useNavigate();
    const [mobileView, setMobileView] = useState(window.innerWidth <= 768);
    const [showImageViewer, setshowImageViewer] = useState(false);
    const [imageViewerIndex, setImageViewerIndex] = useState(0);

    useEffect(() => {
      function updateSize() {
        if (window.innerWidth <= 768) setMobileView(true);
        else setMobileView(false);
      }
      window.addEventListener("resize", updateSize);
    }, []);
  return (
    <>
    <div className="gallerySectionMainContainer">
        <div className="gallerySectionFirstPart">
<img className="gsImg1" src={Gs1} alt="" onClick={()=>{setshowImageViewer(true);setImageViewerIndex(0);}} />
<div className="gsImg23Container" >
<img className="gsImg2" src={Gs2} alt=""  onClick={()=>{setshowImageViewer(true);setImageViewerIndex(1);}} />
<img className="gsImg3" src={Gs3} alt="" onClick={()=>{setshowImageViewer(true);setImageViewerIndex(2);}}  />
</div>
        </div>

        <div className="gallerySectionSecondPart">
<img className="gsImg4" src={Gs4} alt="" onClick={()=>{setshowImageViewer(true);setImageViewerIndex(3);}}  />
<img className="gsImg5" src={Gs5} alt="" onClick={()=>{setshowImageViewer(true);setImageViewerIndex(4);}} />
</div>
    </div>
    {showImageViewer &&
    <Dialog
    open={showImageViewer}
    onClose={()=>{setshowImageViewer(false)}}
  >
    <ClickAwayListener onClickAway={() => {setshowImageViewer(false)}}>
    <ImageViewer showImageViewer={showImageViewer} setshowImageViewer={setshowImageViewer} imageViewerIndex={imageViewerIndex}/>
    </ClickAwayListener>
  </Dialog>
  
    }
    </>
  )
}
export default GallerySection;
