import React, { useEffect, useRef, useState } from "react";
import LoaderIcon from '../assets/loader.gif'

const Loader = () => {

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, width: '100vw', height: '100vh',background:'rgba(0,0,0,0.3)',zIndex:99 }}>
            <img src={LoaderIcon} />
        </div>
    );
};

export default Loader;
