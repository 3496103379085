import "./Header.css";
import React, { useState, useEffect, useRef } from "react";
import IndieCircleLogo from "../assets/Indie_circle_logo.png";
import WhiteFacebookLogo from "../assets/whiteFacebookLogo.png";
import MenuIcon from "../assets/menuIcon.png";
import { useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';

export default function Header() {

  const navigate = useNavigate();

  const [mobileView, setMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 768) setMobileView(true);
      else setMobileView(false);
    }
    window.addEventListener("resize", updateSize);
  }, []);


  const scrollToElement = (id:any) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
<div className="headerMainContainer">
    <div className="headerOverlayDiv">
{!mobileView ?
<div className="headerInnerContainer">

<div>
<div>
        <img src={IndieCircleLogo} style={{width:'6.25vw',height:'6.25vw'}} onClick={()=>{navigate('/')}}/>
</div>
<div className="headerItemMainDiv">
  <div className="headerItemDiv" onClick={()=>{navigate('/')}}>
  Home
  </div>
  <div className="headerItemDiv" onClick={()=>{navigate('/');scrollToElement('destinationPageId')}}>
  Destinations
  </div>
  <div className="headerItemDiv">
  How it works
  </div>
  <div className="headerItemDiv" onClick={()=>{navigate('/SelfDrive')}}>
  4X4 Self-Drive
  </div>
  <div className="headerItemDiv">
  About us
  </div>
  {/* <div className="headerItemDiv">
  Our team
  </div> */}
  <div className="headerItemDiv">
  Sustainability
  </div>
  <div className="headerItemDiv">
  Blog
  </div>
  <div className="headerItemDiv" onClick={()=>{navigate('/ContactUs')}}>
  Contact us
  </div>
  <div className="headerItemDiv" style={{padding:'0.1875vw',borderRadius:'2.5vw',background:'#FAC809',marginTop:'-0.6vw',marginLeft:'2vw'}} onClick={()=>{navigate('/register')/* ;window.location.reload() */}}>
    <div className="registerBtn">
  Register
  </div>
  </div>
</div>
</div>

<div>
<img src={WhiteFacebookLogo} style={{width:'2.1875vw',height:'2.1875vw',marginTop:'0.6vw'}} />
</div>

</div>
:
<div className="headerInnerContainer">
<div>
        <img src={IndieCircleLogo} style={{width:'10vw',height:'10vw'}} onClick={()=>{navigate('/')}}/>
</div>
<Dropdown>
      <Dropdown.Toggle id="dropdown-basic">
        <img src={MenuIcon} alt="" style={{width:'7vw',height:'7vw'}} />
      </Dropdown.Toggle>
      <Dropdown.Menu style={{marginTop:'0vw',marginRight:'2vw'}}>
        <Dropdown.Item onClick={()=>{navigate('/')}}>Home</Dropdown.Item>
        <Dropdown.Item >Destinations</Dropdown.Item>
        <Dropdown.Item >How it works</Dropdown.Item>
        <Dropdown.Item onClick={()=>{navigate('/SelfDrive')}}>4X4 Self-Drive</Dropdown.Item>
        <Dropdown.Item >About us</Dropdown.Item>
        <Dropdown.Item >Our team</Dropdown.Item>
        <Dropdown.Item >Sustainability</Dropdown.Item>
        <Dropdown.Item >Blog</Dropdown.Item>
        <Dropdown.Item  onClick={()=>{navigate('/ContactUs')}}>Contact us</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
</div>
}
    </div>
</div>

  </>
  );
}
