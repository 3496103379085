import React, { useState, useEffect, useRef } from "react";
import "./LandingPage.css";
import WhatsappIcon from "../../assets/WhatsappIcon.png"
import MailIcon from "../../assets/mailIcon.png"
import { countries } from "../../GeneralComponents/Countries";
import { BASE_URL, get, post } from "../../services/Calls";
import { ToastContainer, toast } from 'react-toastify';
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

const ShareYourDetails = () => {

    const [countriesData,setcountriesData] = useState([]);

    const [mobileView, setMobileView] = useState(window.innerWidth <= 768);

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [update, setUpdate] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loader, setLoader] = useState(false)
    const [countryData, setCountryData] = useState([])
    const [selectCountry, setSelectCountry]:any = useState("")

    useEffect(() => {
      getCountries()
    }, [])

    const getCountries = async () => {
        try{
      const response = await get(BASE_URL + '/countries?order=ASC')
      if (response && response.length > 0) {
        setCountryData(response)
      }
    }
    catch(ex){
        //alert(ex)
console.log('error',ex)
    }
    }

  async function sendDetails() {
        try {
            if (name === '') {
                setErrorMsg("Name can't be empty.")
                return;
            }
            if (email === '') {
                setErrorMsg("Email can't be empty.")
                return;
            }
            if (email !== "") {
                let pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                if (!pattern.test(email)) {
                    setErrorMsg("Invalid email format.");
                    return;
                }
            }
            if (phone === '') {
                setErrorMsg("Phone can't be empty.")
                return;
            }
            if (selectCountry === '') {
                setErrorMsg("Please select destination.")
                return;
            }

            let tempEmail = email
            tempEmail = tempEmail.toLowerCase();
            const data = {
                "name": name,
                "email": tempEmail,
                "phone": phone,
                "destination": selectCountry,
                "send_me_updates": update
            };
            setLoader(true)
            const response = await post(BASE_URL + '/contact/share_details', data)
            if (response && response.data && response.data.status == 'ok') {
                setName("")
                setEmail("")
                setPhone("")
                setSelectCountry("")
                setErrorMsg("")
                setUpdate(false)
                notify()
                setLoader(false);
            }
            else {
                setLoader(false);
                setErrorMsg('Something went wrong, please try again later.')
            }
        } catch (ex: any) {
            setLoader(false);
            setErrorMsg(ex);
        }
    }


    const notify = () => {
        toast.success('Thank you for sharing your details.', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    };



    useEffect(() => {
      function updateSize() {
        if (window.innerWidth <= 768) setMobileView(true);
        else setMobileView(false);
      }
      window.addEventListener("resize", updateSize);
    }, []);

    useEffect(()=>(
        setcountriesData(countries)
    ),[]);

    return (
        <ClickAwayListener onClickAway={()=>{setErrorMsg("")}}>
        <div className="shareYourDetailsMainContainer">
        <div className="shareYourDetailsInnerContainer">
        <div className="shareYourDetailsMainHead">
        Kindly share your details; {mobileView && <br/>} we'll be in touch shortly!
        </div>
        <div className="shareYourDetailsItemDiv">
        <input
            type="text"
            name="name"
            placeholder="Name"
            value={name}
            onChange={(e) => { setName(e.target.value) }}
            onFocus={() => { setErrorMsg("") }}
          />
        <input
            type="text"
            name="email"
            placeholder="Email"
            value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                        onFocus={() => { setErrorMsg("") }}
            />
        <input
            type="text"
            name="phone"
            placeholder="Phone"
            value={phone}
                        onChange={(e) => { setPhone(e.target.value) }}
                        onFocus={() => { setErrorMsg("") }}
          />
          <select className="shareYourDetailsSelectDiv" value={selectCountry} onChange={(e)=>{
                setErrorMsg("");setSelectCountry(e.target.value)}}>
  <option value="">Destination</option>
  {countryData && countryData.length>0 && countryData.map((country:any,index:any)=>(
  <option value={country.name} >{country.name}</option>
))}
</select>
{!loader ?
          <div className="shareYourDetailsSendBtn clickableBtn" onClick={() => { sendDetails() }}>
            SEND
          </div>
          :
          <div className="shareYourDetailsSendBtn clickableBtn" style={{opacity:0.7}}>
          SEND...
        </div>
}
        </div>
        {errorMsg != '' && (
                        <div
                            style={{
                                textAlign: 'center',
                                color: 'red',
                                fontSize: '1vw',
                                marginTop:'-1.5vw',
                                marginBottom:'0vw',
                            }}
                        >
                            {errorMsg}
                        </div>
                    )}

        <div className="shareYourDetailsLastDiv">
            <div className="shareYourDetailsCheckDiv">
        <input type="checkbox" checked={update} onChange={(e) => {setUpdate(e.target.checked)}}
                        onFocus={() => {setErrorMsg("") }} />
        <span >Yes, send me updates and advertising offers</span>
        </div>
        <div style={{color: '#3E2A1A',textAlign: 'center',fontFamily: 'interBold',fontSize:mobileView?'3vw':"1.25vw"}}>
        Feel free to contact us at:
        </div>
        <div className="shareYourDetailsSocialIconDiv">
        <img src={WhatsappIcon} />
        <img src={MailIcon} />
        </div>
        </div>
        </div>
        </div>
        </ClickAwayListener>
    )
}
export default ShareYourDetails;
