import React, { useState, useEffect, useRef } from "react";
import "./TripPage.css";
import { useNavigate, useLocation } from 'react-router-dom';
import TripPageBgImg from "../../assets/tripPageBgImg.png"
import JeepWhiteIcon from "../../assets/jeepWhiteIcon.png"
import BlackEnvelopeIcon from "../../assets/blackEnvelopeIcon.png"
import FbBlackIcon from "../../assets/fbBlackIcon.png"
import BlackWhatsappIcon from "../../assets/blackWhatsappIcon.png"
import TravelWithIndie from "../LandingFolder/TravelWithIndie";
import TripDetailLeftSection from "./TripDetailLeftSection";
import TripDetailRightSection from "./TripDetailRightSection";
import GreeceFlag from '../../assets/greeceFlag.png'
import { BASE_URL, get } from "../../services/Calls";
import Loader from "../../GeneralComponents/Loader";
import Header from "../../GeneralComponents/Header";
import Footer from "../../GeneralComponents/Footer";

const TripPage = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [tripsData, setTripsData]: any = useState({})
    const [loader, setLoader] = useState(false)
    const [mobileView, setMobileView] = useState(window.innerWidth <= 768);

    useEffect(() => {
        function updateSize() {
            if (window.innerWidth <= 768) setMobileView(true);
            else setMobileView(false);
        }
        window.addEventListener("resize", updateSize);
    }, []);
    useEffect(() => {
        if (location && location.state && location.state.trip) {
            setTimeout(() => {
                const trip = location.state.trip;
               
                trip.days.forEach((day: { poi_set: any; gpx_set: any; poi_set_sort: string | any[]; 
                    pois_gpxs_data: any[]; }) => {
                     
                    // Combinar los conjuntos de poi_set y gpx_set
                    const allPoisGpxs = [...day.poi_set, ...day.gpx_set];
                   
                /*     console.log("poi_set", day.poi_set)
                    console.log("gpx_set", day.gpx_set) 
                    console.log("poi_set_sort", day.poi_set_sort)  */
                    // Ordenar los elementos combinados según poi_set_sort
                    const sortedPoisGpxs = allPoisGpxs.sort((a, b) => {
                        const aIndex = day.poi_set_sort.indexOf(a.id);
                        const bIndex = day.poi_set_sort.indexOf(b.id);
                        return aIndex - bIndex;
                    });
                    console.log("sortedPoisGpxs", sortedPoisGpxs) 
                    // Agregar la propiedad pois_gpxs_data al día
                    day.pois_gpxs_data = sortedPoisGpxs;
                });
                setTripsData(location.state.trip)
            }, 500);
        }
        else {
            const encodedString = window.location.pathname;
            const decodedString = decodeURIComponent(encodedString.replace('/', ''));
            let tempDecodedString = decodedString.split('/')
            //getTripData(tempDecodedString[1])

        }
        window.scrollTo(0, 0);
    }, []);


    async function getTripData(TripName: any) {
        try {
            setLoader(true)
            const response = await get(BASE_URL + "/trips/name/" + TripName);
            if (response && response.id) {
                setTimeout(() => {
                    response.days.forEach((day: { poi_set: any; gpx_set: any; poi_set_sort: string | any[]; pois_gpxs_data: any[]; }) => {
                        // Combinar los conjuntos de poi_set y gpx_set
                        const allPoisGpxs = [...day.poi_set, ...day.gpx_set];
                        console.log("poi_set", day.poi_set)
                        console.log("gpx_set", day.gpx_set) 
                        console.log("poi_set_sort", day.poi_set_sort) 
                        // Ordenar los elementos combinados según poi_set_sort
                        const sortedPoisGpxs = allPoisGpxs.sort((a, b) => {
                            const aIndex = day.poi_set_sort.indexOf(a.id);
                            const bIndex = day.poi_set_sort.indexOf(b.id);
                            return aIndex - bIndex;
                        });
                        console.log("sortedPoisGpxs", sortedPoisGpxs) 
                        // Agregar la propiedad pois_gpxs_data al día
                        day.pois_gpxs_data = sortedPoisGpxs;
                    });
                    setTripsData(response)
                    setLoader(false)
                }, 500);
            }
        }
        catch (ex) {
            //alert(ex)
console.log('error',ex)
        }
    }



    return (
        <div>
            {tripsData && tripsData.id && !loader ? <>
            <Header/>
            <div className="tripPageFirstSection" style={{ backgroundImage: `url(${TripPageBgImg})` }}>
                <div className="tripPageFirstSectionOverlay">
                    <div className="tripPageOverlayInnerMainDiv">
                        <div className="tripPageOverlayInnerFirstDiv">
                            <img src={JeepWhiteIcon} className="tripPageOverlayJeepWhiteIcon" alt="" />
                            <span className="tripPageOverlayHead">{/* Balkan Ranges highlights along the falls */}{tripsData.name}</span>
                            {!mobileView &&
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: "0.625vw", fontSize: "1.25vw", fontFamily: 'interBold', color: '#fff' }}>
                                    {tripsData && tripsData.country && tripsData.country.id &&
                                        <span>{tripsData.country.name}</span>
                                    }
                                    <span style={{ borderLeft: "0.1875vw solid #fff", height: "1.125vw", background: '#FAC809' }}></span>
                                    {tripsData && tripsData.days && tripsData.days.length > 0 &&
                                        <span>{tripsData.days.length} days</span>
                                    }
                                    {tripsData && tripsData.country && tripsData.country.flagURL &&
                                        <div style={{ height: "2.5vw", width: "2.5vw", borderRadius: '50%', boxShadow: '0vw 0.125vw 0.125vw 0vw #00000040', marginLeft: '1vw' }}>
                                            <img src={tripsData.country.flagURL} style={{ width: '100%', height: '100%', borderRadius: 50, objectFit: 'cover' }} />
                                        </div>
                                    }
                                </div>
                            }
                        </div>

                        {mobileView &&
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: "1vw", fontSize: "4vw", fontFamily: 'interBold', color: '#fff' }}>
                                <span>{tripsData.country.name}</span>
                                <span style={{ borderLeft: "0.5vw solid #fff", height: "4vw", background: '#FAC809' }}></span>
                                <span>{tripsData.days.length} days</span>
                                {tripsData && tripsData.country && tripsData.country.flagURL &&
                                    <div style={{ height: "8vw", width: "8vw", borderRadius: '50%', boxShadow: '0vw 0.125vw 0.125vw 0vw #00000040', marginLeft: '1vw' }}>
                                        <img src={tripsData.country.flagURL} style={{ width: '100%', height: '100%', borderRadius: 50, objectFit: 'cover' }} />
                                    </div>
                                }
                            </div>
                        }

                        <div className="tripPageOverlayInnerSecondDiv">
                            <div className="tripPageOverlayRecommendedSeason">
                                Recommended season
                            </div>
                            <div className="tripPageRecommendedItem">
                                Spring
                            </div>
                            <div className="tripPageRecommendedItem">
                                Autumn
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="destinationPathRow">
                <div className="destinationPaginationDiv">
                    <span className="destinationPaginationHome" onClick={() => { navigate('/') }} >Home</span>
                    <span className="destinationPaginationBorder"></span>
                    {tripsData && tripsData.country && tripsData.country.id &&
                        <span className="destinationPaginationCountry">{tripsData.country.name}</span>
                    }
                </div>
                <div className="destinationSocialIconDiv">
                    <img className="destinationSocialFbIcon" src={FbBlackIcon} />
                    <img className="destinationSocialMailIcon" src={BlackEnvelopeIcon} />
                    <img className="destinationSocialWhatsAppIcon" src={BlackWhatsappIcon} />
                </div>
            </div>

            <div className="tripPageMainDetailDiv">
                <TripDetailLeftSection tripsData={tripsData} />
                <TripDetailRightSection tripsData={tripsData} />
            </div>


            <TravelWithIndie />
            <Footer/>
            </>
        :
        <Loader/>    
        }
        </div>
    )
}
export default TripPage;
