import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { countries } from "../../GeneralComponents/Countries";
import {LocalHeroData} from "./LocalHeroData"
import SliderArrowLeft from "../../assets/sliderArrowLeft.png"
import SliderArrowRight from "../../assets/sliderArrowRight.png"
import { BASE_URL, get } from "../../services/Calls";

const PowerOfTeamSliderNew = () => {

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [mouseEntered, setMouseEntered] = useState<boolean>(false);
  const [mouseMovementX, setMouseMovementX] = useState<number>(0);
  const [currentSilderPosition, setCurrentSliderPosition] = useState<number>(0);
  const [mobileView, setMobileView] = useState<boolean>(
    window.innerWidth <= 768
  );

  const countriesRef = useRef<any>();
  const countryRef = useRef<any>();

  useEffect(() => {
    window.addEventListener("mouseup", handleStopDraggingScroll);
    return () =>
      window.removeEventListener("mouseup", handleStopDraggingScroll);
  }, []);


  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setMouseMovementX(0);
    }, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 768) setMobileView(true);
      else setMobileView(false);
    }
    window.addEventListener("resize", updateSize);
  }, [mobileView]);

  useEffect(() => {
    if (!mobileView) {
      countriesRef.current.style.width =
        countryRef.current?.clientWidth * 0.3125 + "vw";
    }
  }, [mobileView]);

  const handleResize = () => {
    setMobileView(window.innerWidth <= 768);
  };

  const handleStartDraggingScroll = () => {
    setIsDragging(true);
  };

  const handleStopDraggingScroll = () => {
    setIsDragging(false);
  };

  const handleMouseEnter = () => {
    setMouseEntered(true);
  };

  const handleMouseLeave = () => {
    setMouseEntered(false);
  };

  const handleMouseMove = (e: any) => {
    setMouseMovementX(e.movementX);
    if (isDragging && mouseEntered) {
      const moveSpeedDirection =
        e.movementX > 0 ? -e.movementX : Math.abs(e.movementX);
      countriesRef.current.scrollTo(
        countriesRef.current.scrollLeft + moveSpeedDirection,
        0
      );
    }
  };

  const handleScrollPosition = (e: any) => {
    setCurrentSliderPosition(e.target.scrollLeft);
  };

  const arrowLeft = () => {
    if (
      currentSilderPosition ===
      countriesRef.current?.scrollWidth - countriesRef.current?.offsetWidth
    )
      return;
    countriesRef.current.scrollTo(
      countriesRef.current.scrollLeft + countryRef.current?.clientWidth,
      0
    );
  };

  const arrowRight = () => {
    if (currentSilderPosition === 0) return;
    countriesRef.current.scrollTo(
      countriesRef.current.scrollLeft - countryRef.current?.clientWidth,
      0
    );
  };


  const [localHeroes, setLocalHeroes] = useState([]);
  

  useEffect(() => {
      //  setLocalHeroes(LocalHeroData);
  }, []);


  useEffect(() => {
    getLocalExpertData()
  }, [])


  const getLocalExpertData = async () => {
    try{
    const response = await get(BASE_URL + `/local-experts`);
    if (response && response.data && response.data.length > 0) {
      // setLocalExpertData(response.data)
      setLocalHeroes(response.data);
    }
  }
  catch(ex){
      //alert(ex)
console.log('error',ex)
  }
  };


  return (
        <>
        <MainSliderContainer>
          <Swiper>
            <Arrow
              src={SliderArrowLeft}
              style={{
                // transform: "scale(-1)",
                opacity: currentSilderPosition === 0 ? 0.5 : 1,
                height: "3.6025vw",
                width:'3.6025vw',
              }}
              onClick={arrowRight}
            />
            <Countries
              ref={countriesRef}
              onMouseDown={handleStartDraggingScroll}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onMouseMove={handleMouseMove}
              onScroll={handleScrollPosition}
              style={{cursor: window.location.pathname.toLowerCase() == '/becomelocalhero'? "pointer": "unset",
                scrollBehavior:
                  isDragging && mouseMovementX ? "initial" : "smooth",
              }}
            >
                 {localHeroes && localHeroes.length>0 && localHeroes.map((local_hero:any, i:any) => {
                  let index = 0;
                     return (
                <Country key={local_hero.name} ref={countryRef}>
                  <CountryIMGContainer
                    //  onClick={() => {if(setLocalHero)setLocalHero(i)}}
                  >
                    <div
                      style={{
                        textDecoration: "none",
                        color: "black",
                      }}
                    >
                      <CountryIMG src={local_hero.imageURL} />
                    </div>
                    {local_hero && local_hero.country && local_hero.country.flagURL &&
                      <Flag src={local_hero.country.flagURL} />
                    }
                  </CountryIMGContainer>
                </Country>
             );
            })}
            </Countries>
            <Arrow
              src={SliderArrowRight}
              style={{
                // height: "18.5vh",
                height: "3.6025vw",
                width:'3.6025vw',
                opacity:
                  currentSilderPosition ===
                  countriesRef.current?.scrollWidth -
                    countriesRef.current?.offsetWidth
                    ? 0.5
                    : 1,
              }}
              onClick={arrowLeft}
            />
          </Swiper>
        </MainSliderContainer>
        </> 
  );
};

const MainSliderContainer = styled.div`
padding-top: 4.375vw;
padding-bottom: 3.75vw;
@media (max-width: 768px) {
  padding-top: 5vw;
  padding-bottom: 5vw;
}
`;
const Swiper = styled.div`
  width: fit-content;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 74vw;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Arrow = styled.img`
  cursor: pointer;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Countries = styled.div`
z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  max-width: 100vw;
  transition: all 300ms ease;
  width: 80vw !important;
  ::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 768px) {
    width: 100vw !important;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 0vw;
    row-gap: 5vw;
    justify-items: center;
    column-gap: 0vw;
    overflow: initial;
  }
`;
const Country = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 0.91vw;
  :first-child {
    margin-left: 0;
  }
  @media screen and (max-width: 768px) {
    padding: 0 5vw;
  }
`;
const Flag = styled.img`
position: absolute;
bottom: 0vw;
right: 0vw;
width:2.8125vw;
height:2.8125vw;
border-radius: 50%;
@media screen and (max-width: 768px) {
  width:7vw;
  height:7vw;
  right: -1vw;
}
`;
const CountryIMGContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CountryIMG = styled.img`
  width: 9.263vw;
  height: 9.263vw;
  border-radius: 50%;
  background: var(--primary);
  object-fit: cover;
  @media screen and (max-width: 768px) {
    width: 18vw;
    height: 18vw;
  }
`;

export default PowerOfTeamSliderNew;
