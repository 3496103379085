import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "./Footer.css";
import { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import IndieLogo from '../assets/indieLogo2.png'
import MemberLogo from '../assets/memberLogo2.png'
import FbBlackIcon from '../assets/fbBlackIcon.png'
import BottomToTop from '../assets/bottomToTop.png'
import { useNavigate } from "react-router-dom";


const Footer = () => {
  const navigate = useNavigate();
  const [mobileView, setMobileView] = useState<boolean>(
    window.innerWidth <= 768
  );

  useEffect(() => {
  
    function updateSize() {
      if (window.innerWidth <= 768) setMobileView(true);
      else setMobileView(false);
    }
    window.addEventListener("resize", updateSize);
  }, [mobileView]);


  const handleScrollUpClick = (event: any) => {
    window.scrollTo(0, 0);
  };



  return (
    <>
      <Container>
        <div className="footerLogoMainDiv">
          <div className="footerLogoFirstDiv">
            <img className="footerLogoFirstLogo" src={IndieLogo} alt="" />
            <img className="footerLogoSecondLogo" src={MemberLogo} alt="" />
          </div>
            <img className="footerLogoFbLogo" src={FbBlackIcon} alt="" />
          
        </div>
            <div
            className="mainFooterItemDiv"
            >
              <div className="row new_footer_row">
                <div
                  className="col-lg-10 col-md-12 col-sm-12 new_footer_grid_box"
                  style={{ padding: 0 }}
                >
                  <div className="new_footer_item_box">
                    <div className="new_footer_item_head">Company</div>
                    <div
                      className="new_footer_item"
                    >
                      About us
                    </div>
                    <div
                      className="new_footer_item"
                    >
                     About 4x4
                    </div>
                    <div
                      className="new_footer_item"
                     
                    >
                      How it works
                    </div>
                    <div
                      className="new_footer_item"
                     
                    >
                      Our team
                    </div>
                    <div
                      className="new_footer_item"
                      
                    >
                      Sustainability
                    </div>
                  </div>
                  <div className="new_footer_item_box">
                    <div className="new_footer_item_head">Destination</div>
                    <div
                      className="new_footer_item"
                    
                    >
                      Greece
                    </div>
                    <div
                      className="new_footer_item"
                    
                    >
                      Morocco
                    </div>
                    <div
                      className="new_footer_item"
                    
                    >
                      Greece
                    </div>
                    <div
                      className="new_footer_item"
                    
                    >
                      Morocco
                    </div>
                    <div
                      className="new_footer_item"
                    
                    >
                      Greece
                    </div>
                    <div
                      className="new_footer_item"
                    
                    >
                      Morocco
                    </div>
                    <div
                      className="new_footer_item"
                    
                    >
                      Greece
                    </div>
                    <div
                      className="new_footer_item"
                    
                    >
                      Morocco
                    </div>
                 
                  </div>
                  <div className="new_footer_item_box">
                    <div className="new_footer_item_head">Destination</div>
                    <div
                      className="new_footer_item"
                    
                    >
                      Greece
                    </div>
                    <div
                      className="new_footer_item"
                    
                    >
                      Morocco
                    </div>
                    <div
                      className="new_footer_item"
                    
                    >
                      Greece
                    </div>
                    <div
                      className="new_footer_item"
                    
                    >
                      Morocco
                    </div>
                    <div
                      className="new_footer_item"
                    
                    >
                      Greece
                    </div>
                    <div
                      className="new_footer_item"
                    
                    >
                      Morocco
                    </div>
                    <div
                      className="new_footer_item"
                    
                    >
                      Greece
                    </div>
                    <div
                      className="new_footer_item"
                    
                    >
                      Morocco
                    </div>
                 
                  </div>
               
                  <div className="new_footer_item_box">
                    <div className="new_footer_item_head">Legal</div>
                    <div
                      className="new_footer_item"
                      onClick={()=>{navigate('/PrivacyPolicy')}}
                    >
                      Privacy policy
                    </div>
                    <div
                      className="new_footer_item"
                      onClick={()=>{navigate('/TermsOfUse')}}
                    >
                      Terms of use
                    </div>
                    <div
                      className="new_footer_item"
                     
                    >
                      Site map
                    </div>
                  </div>
                  <div className="new_footer_item_box">
                    <div className="new_footer_item_head">Support</div>
                    <div
                      className="new_footer_item"
                     
                    >
                      Cancellation policy
                    </div>
                    <div className="new_footer_item" onClick={()=>{navigate('/ContactUs')}}>
                    Contact us
                    </div>  
                    
                  </div>
                 
                </div>



                <div className="col-lg-2 col-md-12 col-sm-12 ">

                </div>
              </div>
              <img
                src={BottomToTop}
                onClick={handleScrollUpClick}
                className="new_back_to_top"
                alt=""
              />
            </div>
            <div className="footerCopyRightDiv">
            © Copyright 2024, iNDiE
            </div>
          
      </Container>

    </>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #F8F8F6;
`;

export default Footer;
