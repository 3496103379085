import * as React from "react";
import * as ReactDOM from "react-dom";
import { PanViewer } from "react-image-pan-zoom-rotate";
// import BackArrowImageViewer from '../../assets/backArrowImageViewer.png'
import ImageLogo from '../../assets/imageLogo.png'
import VideoLogo from '../../assets/videoLogo.png'
import CrossIconImage from '../../assets/whiteCrossIconImage.png'
import DownloadsIcon from '../../assets/downloads.png'
import Gs1 from "../../assets/gs1.png"
import Gs2 from "../../assets/gs2.png"
import Gs3 from "../../assets/gs3.png"
import Gs4 from "../../assets/gs4.png"
import Gs5 from "../../assets/gs5.png"
import SliderArrowLeft from "../../assets/sliderArrowLeft.png"
import SliderArrowRight from "../../assets/sliderArrowRight.png"

interface IProps {
    showImageViewer: any;
    setshowImageViewer: any;
    imageViewerIndex: any;
}


function ImageViewer({ showImageViewer, setshowImageViewer, imageViewerIndex }: IProps) {
    const [dx, setDx] = React.useState(0);
    const [dy, setDy] = React.useState(0);
    const [zoom, setZoom] = React.useState(1);
    const [rotation, setRotation] = React.useState(0);
    const [flip, setFlip] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState(imageViewerIndex);

    const [images, setImages] = React.useState([
        { src: Gs1, alt: 'Image 1' },
        { src: Gs2, alt: 'Image 2' },
        { src: Gs3, alt: 'Image 3' },
        { src: Gs4, alt: 'Image 3' },
        { src: Gs5, alt: 'Image 3' },
        // Add more images as needed
    ]);

    const [mobileView, setMobileView] = React.useState(window.innerWidth <= 768);

    React.useEffect(() => {
        function updateSize() {
            if (window.innerWidth <= 768) setMobileView(true);
            else setMobileView(false);
        }
        window.addEventListener("resize", updateSize);
    }, []);


    const handleIndexChange = (type: any) => {
        setZoom(1);
        let index = currentIndex
        if (type == 'prev') {
            if(index != 0){
                setCurrentIndex(index - 1);
            }
        }
        else {
            if(index != 4){
                setCurrentIndex(index + 1);
            }
        }
    };

    const resetAll = () => {
        setDx(0);
        setDy(0);
        setZoom(0);
        setRotation(0);
        setFlip(false);
    };
    const zoomIn = () => {
        setZoom(zoom + 0.1);
    };

    const zoomOut = () => {
        if (zoom >= .2) {
            setZoom(zoom - 0.1);
        }
    };

    const rotateLeft = () => {
        if (rotation === -3) {
            setRotation(0);
        } else {
            setRotation(rotation - 1);
        }
    };

    const flipImage = () => {
        setFlip(!flip);
    };

    const onPan = (dx: number, dy: number) => {
        setDx(dx);
        setDy(dy);
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                <div
                    style={{
                        position: 'absolute',
                        zIndex: 2,
                        // top: 10,
                        userSelect: 'none',
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        alignItems: 'center',
                        bottom: 0,
                        gap: 20,
                    }}
                >

<div
                        onClick={() => { handleIndexChange('prev') }}
                        style={{
                            textAlign: 'center',
                            cursor: 'pointer',
                            height: 40,
                            width: 40,
                            borderBottom: '0.0625vw solid #ccc',
                            borderRadius: 4,
                            background: '#fff',
                            boxShadow: '0vw 0.125vw 0.375vw rgba(53, 67, 93, 0.32)',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            marginRight:'2vw'
                        }}
                    >
                      <img src={SliderArrowLeft} style={{width:'70%'}} />
                    </div>
                    <div
                        onClick={zoomOut}
                        style={{
                            textAlign: 'center',
                            cursor: 'pointer',
                            height: 40,
                            width: 40,
                            borderBottom: '0.0625vw solid #ccc',
                            borderRadius: 50,
                            background: '#fff',
                            boxShadow: '0vw 0.125vw 0.375vw rgba(53, 67, 93, 0.32)',
                        }}
                    >
                        <svg
                            style={{
                                height: '100%',
                                width: '100%',
                                padding: 10,
                                boxSizing: 'border-box',
                            }}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4 12H20"
                                stroke="#4C68C1"
                                strokeWidth="2"
                                strokeLinecap="round"
                            />
                        </svg>
                    </div>
                    <div
                        onClick={zoomIn}
                        style={{
                            textAlign: 'center',
                            cursor: 'pointer',
                            height: 40,
                            width: 40,
                            borderBottom: '0.0625vw solid #ccc',
                            borderRadius: 50,
                            background: '#fff',
                            boxShadow: '0vw 0.125vw 0.375vw rgba(53, 67, 93, 0.32)',
                        }}
                    >
                        <svg
                            style={{
                                height: '100%',
                                width: '100%',
                                padding: 10,
                                boxSizing: 'border-box',
                            }}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4 12H20"
                                stroke="#4C68C1"
                                strokeWidth="2"
                                strokeLinecap="round"
                            />
                            <path
                                d="M12 4L12 20"
                                stroke="#4C68C1"
                                strokeWidth="2"
                                strokeLinecap="round"
                            />
                        </svg>
                    </div>
                    <div
                        onClick={() => { handleIndexChange('next') }}
                        style={{
                            textAlign: 'center',
                            cursor: 'pointer',
                            height: 40,
                            width: 40,
                            borderBottom: '0.0625vw solid #ccc',
                            borderRadius: 4,
                            background: '#fff',
                            boxShadow: '0vw 0.125vw 0.375vw rgba(53, 67, 93, 0.32)',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            marginLeft:'2vw'
                        }}
                    >
                    <img src={SliderArrowRight} style={{width:'70%'}}  />
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        zIndex: 2,
                        top: 10,
                        userSelect: 'none',
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center'
                    }}
                >
                    <div
                        style={{
                            textAlign: 'center',
                            fontSize: 16,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 20
                        }}
                    >
                        {/* <img style={{width:22,cursor: 'pointer',}} src={BackArrowImageViewer} alt="" /> */}
                        {/* <img  src={ImageLogo} alt="" /> */}
                        {/* <span style={{color:'white'}}>{fileName}</span> */}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 22 }}>
                        <img style={{ width: 18, height: 18, cursor: 'pointer' }} src={CrossIconImage} alt="" onClick={() => { setshowImageViewer(false) }} />
                    </div>
                </div>

                <PanViewer
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1,
                    }}
                    zoom={zoom}
                    setZoom={setZoom}
                    pandx={dx}
                    pandy={dy}
                    onPan={onPan}
                    rotation={rotation}
                    key={dx}
                // onClick={handleCloseImageViewer}
                >
                    <img
                        // onClick={(e)=>{e.preventDefault();e.stopPropagation()}}
                        style={{
                            // transform: `rotate(${rotation * 90}deg) scaleX(${flip ? -1 : 1})`,
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                            height: mobileView ? '100vw' : '35vw',
                        }}
                        src={images[currentIndex].src}
                    // alt={alt}
                    // ref={ref}
                    />
                    {/* </ClickAwayListener> */}
                </PanViewer>
            </div>
        </>
    );
};

export default ImageViewer