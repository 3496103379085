import React, { useState, useEffect, useRef } from "react";
//import "./DeleteUserData.css";
import { useNavigate, useLocation } from 'react-router-dom';
import VideoPage from "../LandingFolder/VideoSection";
import BlackEnvelopeIcon from "../../assets/blackEnvelopeIcon.png"
import FbBlackIcon from "../../assets/fbBlackIcon.png"
import BlackWhatsappIcon from "../../assets/blackWhatsappIcon.png"
import DropDownArrow from "../../assets/dropDownArrow.png"
import GallerySection from "../Destinations/GallerySection";
import { BASE_URL, get, post, login,DeleteUser } from "../../services/Calls";
import AuthLoader from '../../assets/authLoader.gif'
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { ToastContainer, toast } from 'react-toastify';
import Header from "../../GeneralComponents/Header";
import Loader from "../../GeneralComponents/Loader";
import Footer from "../../GeneralComponents/Footer";
//import './New.css'
const DeleteUserData = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [errorMsg, setErrorMsg] = useState("")
    const [loader, setLoader] = useState(false)
    const [countryData, setCountryData]: any = useState([])
    const [country, setCountry]: any = useState({})
    const [showCountrySelect, setShowCountrySelect] = useState(false);

    const [scrollY, setScrollY] = useState(0);
    const [mobileView, setMobileView] = useState(window.innerWidth <= 768);
    const [otpscreen, setOtpScreen] = useState(false);

    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const otpLength = otp.length;
    const [loginData, setLoginData] = useState({ "email": '', "password": ''})



    const handleChange = (index:any, value:any) => {
        // Ensure that the value is a single digit
        if (/^[0-9]$/.test(value)) {
          // Update the OTP array with the new value
          setOtp((prevOtp) => {
            const newOtp = [...prevOtp];
            newOtp[index] = value;
            return newOtp;
          });
    
          // Move to the next input field
          if (index < otp.length - 1 && value !== '' ) {
            document?.getElementById(`otp-input-${index + 1}`)?.focus();
          }
        }
      };
    



      const handlePaste = (event:any) => {
        event.preventDefault();
        const pastedData = event.clipboardData.getData('Text').split('');
    
        // Update OTP values based on pasted data
        setOtp((prevOtp) => {
          const newOtp = [...prevOtp];
          pastedData.forEach((digit:any, index:any) => {
            if (index < otpLength) {
              newOtp[index] = digit;
            }
          });
    
          if (pastedData.length < 6) {
            document?.getElementById(`otp-input-${pastedData.length}`)?.focus();
          }
          else {
            document?.getElementById(`otp-input-${pastedData.length - 1}`)?.focus();
          }
          return newOtp;
        });
      };
    
    
      const handleBackspace = (index:any) => {
        // Update the OTP array with the new value
        setOtp((prevOtp) => {
          const newOtp = [...prevOtp];
          newOtp[index] = '';
          return newOtp;
        });
    
        // Move to the next input field
        if (index < otp.length && index > 0) {
          document?.getElementById(`otp-input-${index - 1}`)?.focus();
        }
      };
    

      const twoFactorSubmit = async () => {
        try {
          if (otp[0] == '' || otp[1] == '' || otp[2] == '' || otp[3] == '' || otp[4] == '' || otp[5] == '') {
            alert('invalid input')
            return;
          }
         
          setLoader(true)
          const response = await post(BASE_URL + '/auth/two-factor', {
            mfa_code: parseInt(otp.join(''), 10),
            email: loginData?.email,
          });
    
          if (response && response.data.token && response.data.token.accessToken) {
           // localStorage.setItem('token', response.data.token.accessToken);
           DeleteUser(response.data.token.accessToken)
            //setLoader(false);
            //window.location.reload();
          }
          else {
            setLoader(false);
            alert('something went wrong, please try again later.')
          }
    
        } catch (ex:any) {
          if (ex && ex.response && ex.response.data && ex.response.data.message) {
            setLoader(false);
            alert(ex.response.data.message)
          }
          else {
            setLoader(false);
            alert(ex)
          }
        }
    
      }
   
    
    useEffect(() => {
      function updateSize() {
        if (window.innerWidth <= 768) setMobileView(true);
        else setMobileView(false);
      }
      window.addEventListener("resize", updateSize);
    }, []);

 
  
    // useEffect(() => {
    //   function updateScroll() {
    //     setScrollY(window.scrollY);
    //   }
    //   window.addEventListener('scroll', updateScroll);

    // }, []); 


    useEffect(() => {
        getCountries()
        window.scrollTo(0, 0);
    }, []);
    const getCountries = async () => {
        try{
        const response = await get(BASE_URL + '/countries?order=ASC')
        if (response && response.length > 0) {
            setCountryData(response)
        }
    }
    catch(ex){
        //alert(ex)
console.log('error',ex)
    }
    }



    const handleSubmit = async (event: any) => {

        event.preventDefault();
        const formData = event.currentTarget.elements;

        try {
            
            if (formData[0].value === '') {
                setErrorMsg("Email can't be empty.")
                return;
            }
            if (formData[0].value !== "") {
                let pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                if (!pattern.test(formData[0].value)) {
                    setErrorMsg("Invalid email format.");
                    return;
                }
            }
            if (formData[1].value === '') {
                setErrorMsg("Password can't be empty.")
                return;
            }
           

          
            const data = {
                "email": formData[0].value,
               
                "password": formData[1].value,
              
            };
            setLoginData(data);
            setLoader(true)
          


              const response = await login(BASE_URL + '/auth/login', data)
             // const response= {data:{status:'ok'}}
              if (response && response?.status == 'ok') {
                
                //notify()  
                setLoader(false);
                setOtpScreen(true);
                //navigate('/')

              }
              else{
                setLoader(false);
                setErrorMsg('Invalid Email or Password')
              }
        } catch (ex:any) {
            setLoader(false);
            setErrorMsg(ex);
        }

    };
    const DeleteUser = async (token: any) => {

        try {
              setLoader(true)
              const response:any = await DeleteUser(BASE_URL + '/users/delete-user');
              if (response  && response?.message ) 
              {
                setLoader(false);
                setErrorMsg(response?.message);
              }
              else{
                notify()  
                setLoader(false);
                navigate('/');
              }
        } catch (ex:any) {
            setLoader(false);
            setErrorMsg(ex);
        }

    };

    const notify = () => {
        toast.success('Your Data deleted Successfully.', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    };

    return (
        <div>
            <Header/>
           {/*  <VideoPage pageFrom={'DeleteUserData'} countryname={""} /> */}
            {/* <div className="destinationPathRow" id='contactUsPageId'>
                <div className="destinationPaginationDiv">
                    <span className="destinationPaginationHome" onClick={() => {navigate('/')}} >Home</span>
                    <span className="destinationPaginationBorder"></span>
                    <span className="destinationPaginationCountry">Delete User data</span>
                </div>
                <div className="destinationSocialIconDiv">
                    <img className="destinationSocialFbIcon" src={FbBlackIcon} />
                    <img className="destinationSocialMailIcon" src={BlackEnvelopeIcon} />
                    <img className="destinationSocialWhatsAppIcon" src={BlackWhatsappIcon} />
                </div>
            </div> */}

<div /* className={`${scrollY>200?"wow bounceInUp":""}`} */ className="wow bounceInUp">
            <div className="ContactUsFormDiv" style={{marginTop:140}}>

{!otpscreen &&
                <form onSubmit={handleSubmit} >
                   
                    <div style={{ fontSize: mobileView ? 20 : 35, fontWeight: '800', fontFamily: 'interBold', textAlign: 'center',marginBottom:50  }}>
                    Delete User Data
                  </div>
                    <div className='contactUsInputCss'>
                        <input
                            type="email"
                            name="email"
                            placeholder='Email'
                            onFocus={() => { setErrorMsg('') }}
                        />
                    </div>

                    <div className='contactUsInputCss'>
                        <input
                            type="password"
                            name="password"
                            placeholder='Password'
                            onFocus={() => { setErrorMsg('') }}
                        />
                    </div>

                  
                   
                    {errorMsg != '' && (
                        <div
                            style={{
                                textAlign: 'center',
                                color: 'red',
                                fontSize: mobileView?"3.5vw":'1vw',
                            }}
                        >
                            {errorMsg}
                        </div>
                    )}
                    <div className='contactUsPageBtnUpperDiv' style={{textAlign:'center'}} >
                        <button type="submit" className='contactUsPageBtn'>Delete Data</button>
                    </div>
                </form>
}
{otpscreen &&
            <>
            <div>
                   <form>
                   <div style={{ fontSize: mobileView ? 20 : 35, fontWeight: '800', fontFamily: 'interBold', textAlign: 'center',marginBottom:50  }}>
                    Otp sent to your email ID
                  </div>
                     <div className='two-factor-form'>
                       {otp.map((digit, index) => (
                         <input
                           key={index}
                           type="text"
                           id={`otp-input-${index}`}
                           name={`otp-input-${index}`}
                           autoFocus={index === 0}
                           value={digit}
                           onChange={(e) => handleChange(index, e.target.value)}
                           onPaste={handlePaste}
                           onKeyDown={(e) => {
                             if (e.key === 'Backspace') {
                               handleBackspace(index);
                             }
                           }}
                         />
                       ))}
                     </div>
                     {otp[0] != '' &&
                       <div style={{ marginTop: 20,textAlign:'center' }}>
                         <button style={{ background: '#fff', color: '#000', border: '1px solid grey' }} className='loginBtn' onClick={() => { setOtp(['', '', '', '', '', '']) }}>Clear</button>
                       </div>
                     }
                   </form>
                   <div style={{ marginTop: otp[0] != '' ? 10 : 30,textAlign:'center' }}>
                     <button style={{color: '#000'}} className='contactUsPageBtn' onClick={() => { twoFactorSubmit() }}>Submit</button>
                   </div>
                   {/* <p>Entered OTP: {otp.join('')}</p> */}
       
                 </div>

                </>
}
            </div>
            </div>
            <div style={{ height: '6.625vw' }}></div>
            {loader &&
                <Loader/>
            }
            <Footer/>
        </div>
    )
}
export default DeleteUserData;
