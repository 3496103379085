import React, { useState, useEffect, useRef } from "react";
import "./DestinationsPage.css";
import VideoPage from "../LandingFolder/VideoSection";
import BlackEnvelopeIcon from "../../assets/blackEnvelopeIcon.png"
import FbBlackIcon from "../../assets/fbBlackIcon.png"
import BlackWhatsappIcon from "../../assets/blackWhatsappIcon.png"
import { useNavigate } from 'react-router-dom';
import MostPopular from "../LandingFolder/MostPopular";
import GallerySection from "./GallerySection";
import HeadlineRatingStar from "../../assets/headlineRatingStar.png"
import { useLocation } from 'react-router-dom';
import { BASE_URL, get } from "../../services/Calls";
import Loader from "../../GeneralComponents/Loader";
import Header from "../../GeneralComponents/Header";
import Footer from "../../GeneralComponents/Footer";

const DestinationsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [countryData, setCountryData]: any = useState({})
    const [loader, setLoader] = useState(false)
    const [mobileView, setMobileView] = useState(window.innerWidth <= 768);

    useEffect(() => {
        function updateSize() {
            if (window.innerWidth <= 768) setMobileView(true);
            else setMobileView(false);
        }
        window.addEventListener("resize", updateSize);
    }, []);

    useEffect(() => {
        if (location && location.state && location.state.country) {
            setTimeout(() => {
                setCountryData(location.state.country)
            }, 500);
        }
        else {
            const encodedString = location.pathname;
            const decodedString = decodeURIComponent(encodedString.replace('/', ''));
            getCountries(decodedString)
        }
        window.scrollTo(0, 0);
    }, []);



    
      const getCountries = async (countryName:any) => {
        try{
            setLoader(true)
        const response = await get(BASE_URL + '/countries?order=ASC')
        if (response && response.length > 0) {
            for(let i=0;i<response.length;i++){
                if(response[i].name == countryName){
                    setTimeout(() => {
                        setCountryData(response[i])
                        setLoader(false)
                    }, 500);
                }
            }
          
        }
      }
      catch(ex){
          //alert(ex)
console.log('error',ex)
      }
      }


    return (
        <>
        {countryData && countryData.id && !loader ? 
        <div>

      <Header/>
            <VideoPage pageFrom={'destination'} countryname={countryData.name} />
            <div className="destinationPathRow" id="destinationPageId">
                <div className="destinationPaginationDiv">
                    <span className="destinationPaginationHome" onClick={() => { navigate('/') }} >Home</span>
                    <span className="destinationPaginationBorder"></span>
                    <span className="destinationPaginationCountry">{countryData.name}</span>
                </div>
                <div className="destinationSocialIconDiv">
                    <img className="destinationSocialFbIcon" src={FbBlackIcon} />
                    <img className="destinationSocialMailIcon" src={BlackEnvelopeIcon} />
                    <img className="destinationSocialWhatsAppIcon" src={BlackWhatsappIcon} />
                </div>
            </div>


            <MostPopular pageFrom={'destination'} />
            <div className="destinationFollowLine">
                Follow  # iNDiE {countryData.name}
            </div>

            <GallerySection />

            <div className="destinationDescription">
                <div className="destinationMainCountryName">
                    {countryData.name}
                </div>
                <div className="destinationMainDescription">
                    {/* Driving with iNDiE offers a unique and unforgettable travel experience. Our curated itineraries take you off the beaten path, allowing you to explore hidden gems and immerse yourself in the local culture. With iNDiE, you have the freedom to customize your journey, choosing from a range of activities and destinations. Our expert guides are passionate about providing you with an authentic and enriching adventure. Plus, our commitment to safety and sustainability ensures you can travel with confidence and a clear conscience. Choose iNDiE for a travel experience that's truly one-of-a-kind. */}
                    {countryData.description}
                </div>
            </div>

            <div className="destinationRecommendLine">
                Travelers highly recommend
            </div>

            <div className="HeadlineItemDiv" style={mobileView ? { padding: '4vw', gap: '6vw' } : {}}>
                <div>
                    <img src={HeadlineRatingStar} alt="" />
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <span className="HeadlineItemPersonName">Judi  Y.</span>
                        <span className="HeadlineItemGoogle">Google</span>
                    </div>
                    <div>
                        "In recent years, we've exclusively traveled with Medraft, from the enchanting Lapland to captivating destinations like Greece, Portugal, and the Emirates. Each trip is like stepping into a nature movie, filled with rich experiences, attractions, and everything you need to truly savor the places you visit. They excel in paying attention to even the smallest details."
                    </div>
                </div>
                <div>
                    <img src={HeadlineRatingStar} alt="" />
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <span className="HeadlineItemPersonName">Efrat B.</span>
                        <span className="HeadlineItemGoogle">Google</span>
                    </div>
                    <div>
                        "I had an incredible experience with Medraft on a trip to Montenegro. It was fun, professional, diverse, and truly enriching. It even felt life-changing. This was my first organized trip, and it won't be my last. A big thank you to the fantastic Madraft team!"
                    </div>
                </div>
                <div>
                    <img src={HeadlineRatingStar} alt="" />
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <span className="HeadlineItemPersonName">Noga G.</span>
                        <span className="HeadlineItemGoogle">Google</span>
                    </div>
                    <div>
                        "For several years, we've chosen Medraft for our journeys to unique and alluring destinations. Their jeeps take us to places buses can't reach, unveiling hidden gems. The company is exceptionally organized, with top-notch guides who cater to every request. We've traveled with them before and will continue to do so. They're true champions!"
                    </div>
                </div>

            </div>

            <div style={{ height: '10.375vw' }}>

            </div>

            <Footer/>
        </div>
        :
        <Loader/>
        }
        </>
    )
}
export default DestinationsPage;
