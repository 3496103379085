import React, { useState, useEffect, useRef } from "react";
import "./LandingPage.css";
import CareImg1 from "../../assets/careImg1.png"
import CareImg2 from "../../assets/careImg2.png"
import CareImg3 from "../../assets/careImg3.png"
import CareImg4 from "../../assets/careImg4.png"

const Care = () => {

    const [mobileView, setMobileView] = useState(window.innerWidth <= 768);

    useEffect(() => {
        function updateSize() {
            if (window.innerWidth <= 768) setMobileView(true);
            else setMobileView(false);
        }
        window.addEventListener("resize", updateSize);
    }, []);

    return (
        <div className="careMainContainer">
            <div className="careOverlayDiv">
                <div className="careInnerContainer">
                    <div>
                        {!mobileView ?
                            <div style={{ fontSize: "3.625vw", fontWeight: '700', fontFamily: 'interBold', color: '#fff', textAlign: 'center', lineHeight: '4.375vw' }}>
                                We care <br /> about our <br /> sustainability
                            </div>
                            :
                            <div style={{ fontSize: "5.3vw", fontWeight: '700', fontFamily: 'interBold', color: '#fff', textAlign: 'center', lineHeight: '4.375vw' }}>
                                We care about our sustainability
                            </div>
                        }
                        <div className="careCommittedBtn clickableBtn">
                            Learn how we are Committed
                        </div>
                    </div>
                    <div className="careSecondItemDiv" style={{ gap: "2.375vw" }}>
                        <div className="careSecondItemAllImgs">
                            <img src={CareImg1} />
                            <img src={CareImg2} />
                            <img src={CareImg3} />
                            <img src={CareImg4} />
                        </div>
                        <div className="careSecondItemDes">
                            <p>
                                As travelers, we all care about local cultures and their environment. For us, it is not just a slogan, but a way of life. It is quite simple, without it, there is no life, there is no travel.
                            </p>
                            <p>
                                That is why we optimize every travel, every experience, and every adventure so it is as clean and sustainable as possible and pays the utmost respect to the local culture and community.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Care;
