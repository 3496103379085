import React, { useEffect, useState } from "react";
import './PrivacyPolicy.css'
// import { Button, Dialog, DialogTitle, Modal } from "@material-ui/core";
import exitBtn from "../../resources/exit-btn.png";
import styled from "styled-components";
import Header from "../GeneralComponents/Header";
import VideoPage from "./LandingFolder/VideoSection";
import Footer from "../GeneralComponents/Footer";
const Content = styled.p`
  //   overflow-y: scroll;
  font-size: small;
  max-width: 80%;
  margin: 0 auto;
  padding: 30px 0px;
`;

const PrivacyPolicy = () => {
  const [mobileView, setMobileView] = useState<boolean>(
    window.innerWidth <= 768
  );

  useEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 768) setMobileView(true);
      else setMobileView(false);
    }
    window.addEventListener("resize", updateSize);
  }, [mobileView]);

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <>
     <Header/>
     <VideoPage pageFrom={'PrivacyPolicy'} countryname={""} />
{/* <div style={{fontSize:32,fontWeight:500,color:'#FAC809',textAlign:'center'}}>
Privacy Policy
</div> */}

      <Content id="PrivacyPageId">
        <p>
          This Privacy Policy (<b>"Privacy Policy"</b>) is an integral part of
          our{" "}
          <span
            // onClick={() => goToTOU()}
            style={{
              textDecorationLine: "underline",
              color: "#2d81fd",
              cursor: "pointer",
            }}
          >
            Terms of Use
          </span>{" "}
          (<b>"Terms"</b>) and governs the processing of information by
          Expenture Travel Ltd. operating under the brand name Indie4x4 (
          <b>"Indie4x4," "Company," "we," "us,"</b> or <b>"our"</b>) while
          you (<b>"you"</b> or <b>"user"</b>) access, use or interact with our
          mobile application (<b>"App"</b>) and website available at:{" "}
          <a href="https://indie4x4-frontend-4ec910c88de3.herokuapp.com/" target="blank">
          https://indie4x4-frontend-4ec910c88de3.herokuapp.com/
          </a>{" "}
          (<b> "Website"</b>) and the services provided therein (collectively,
          the <b>"Services"</b>).
        </p>
        <p>
          Any capitalized terms not defined herein shall have the meaning
          ascribed to them in the Terms.
        </p>
        <p>
          Indie4x4 may update or revise this Privacy Policy from time to
          time. Modifications to this Policy will be posted on the Website and
          App or addressed directly to where the Company, upon its sole
          discretion, finds it required.
        </p>
        <p>
          This Privacy Policy explains how the data is collected, used, or
          shared with others, how it is safeguarded, and how an individual may
          exercise their lawful rights related, among others, and where
          applicable, as required according to the EU General Data Protection
          Regulation (<b>"GDPR"</b>).
        </p>
        <p>
          <b>
            Please note that you are not required by law to provide us with
            Personal Data (as defined below). Providing Personal Data to us is
            entirely voluntary, provided however, that we will not be able to
            provide you with certain services or information without obtaining
            your Personal Data
          </b>{" "}
          (for example, we will not be able to contact you in response to your
          inquiry if we will not have your contact details).
        </p>
        <p>
          Indie4x4 shall be entitled to (however not obligated), to retain
          and store Personal Data in its databases, as detailed under this
          Privacy Policy, and subject to applicable law.
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>
            1){" "}
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              CONTACT DETAILS
            </span>
          </span>
          <p>
            <b>Expenture Travel Ltd.,</b> incorporated under the laws of the
            state of Israel, is the Controller (as such term is defined under
            the GDPR or equivalent privacy legislation) of the Personal Data we
            collect from you.
          </p>
          <p>
            For any question, inquiry or concern related to this Privacy Policy
            or the processing of your Personal Data, you may contact as follows:
          </p>
          <p>
            <b>DPO Contact Information: </b>
            <a href="mailto: support@Indie4x4.com">
              support@Indie4x4.com.
            </a>
          </p>
          <p>
            <b>By Mail: Hatarshish 15 Industry Park, Caesarea Israel.</b>
          </p>
          <p>
            <b>EU DPR Contact Information:</b>
            <a href="mailto: Doron@Indie4x4.com"> Doron@Indie4x4.com</a>
            , Monodedri Ioannina 44007, Ioannina, Greece.
          </p>
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>
            2){" "}
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              WHICH DATA DO WE COLLECT AND FOR WHAT PURPOSE?
            </span>
          </span>
          <p>
            <b>"Non-Personal Data":</b> means non-identifiable, aggregated data,
            that is mainly technical data which is transmitted to us by your
            device when you access and interact with our Website or App, such as
            the type of browser, type of operating system, type of device used,
            the time and date you access the Website or App, navigation,
            language preference, etc. Non-Personal data is used mainly for click
            stream analysis to provide, maintain, develop and enhance our
            Website or App and the Services provided therein (if applicable) and
            is not used to identify individuals.
          </p>
          <p>
            <b>"Personal Data":</b> means individually identifiable information
            which identifies or may identify, with reasonable effort, an
            individual, including online identifiers, such as your name,
            address, phone number, and online identifiers (such as IP address).
          </p>
          <p>
            <b>
              Please see below the table which specifies the Personal Data we
              collect and how we use it.
            </b>
          </p>
          {mobileView ? (
            <div className="App privacy_table">
              <table
                /* className="privacy_table" */ style={{
                  width: "100%",
                  marginBottom: 20,
                }}
              >
                <tr>
                  <th>DATA SET</th>
                </tr>
                <tr>
                  <td>
                    <b>
                      <span style={{ textDecorationLine: "underline" }}>
                        Contact Us
                      </span>
                    </b>
                    <p>
                      If you voluntarily contact us in order to ask for
                      information regarding our Services or any other inquiry,
                      you may be required to provide us with certain information
                      such as your name, email address, and additional
                      information you decide to share with us (
                      <b>"Contact Information"</b>).
                    </p>
                    <p>
                      If you are contacting us on behalf of another person, we
                      value your assistance and care for others, please note
                      that it is your responsibility to make sure that any
                      person whose Personal Data you provide is aware of the
                      principles of this statement and agrees that you will
                      provide Personal Data to us on this basis.
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>PURPOSE AND OPERATIONS</th>
                </tr>
                <tr>
                  <td>
                    <p>
                      We will use this data to provide you with the information
                      you requested, respond to your inquiry, or provide our
                      Services. We may process the content of our correspondence
                      with you to improve customer service, and in the event we
                      believe it is required in order to provide you with any
                      further assistance (if applicable).
                    </p>
                    <p>
                      The correspondence and its contents with you may be
                      processed and stored by us in order to improve our
                      customer service and in the event, we believe it is
                      required to continue to store it, for example, in the
                      event of any claims or in order to provide you with any
                      further assistance (if applicable).
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>LAWFUL BASIS</th>
                </tr>
                <tr>
                  <td>
                    {" "}
                    We process such Contact Information and retain any inquiry
                    history subject to our legitimate interests.
                  </td>
                </tr>
              </table>

              <table
                /* className="privacy_table" */ style={{
                  width: "100%",
                  marginBottom: 20,
                }}
              >
                <tr>
                  <th>DATA SET</th>
                </tr>
                <tr>
                  <td>
                    <b>
                      <span style={{ textDecorationLine: "underline" }}>
                        Online Identifiers
                      </span>
                    </b>
                    <p>
                      We use third-party cookies on the Website. As further
                      detailed below, these cookies provide us with analytic and
                      marketing services. The Personal Data processed is an
                      online identifier, either a cookie agent, the IP address,
                      etc. (<b>"Online Identifiers"</b>).
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>PURPOSE AND OPERATIONS</th>
                </tr>
                <tr>
                  <td>
                    <p>
                      We will use your Online Identifiers for analytic and
                      marketing purposes.
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>LAWFUL BASIS</th>
                </tr>
                <tr>
                  <td>
                    <p>
                      Strictly necessary cookies which are required for the
                      proper and basic operation of the Website or App will be
                      processed in our legitimate interest.
                    </p>
                    <p>
                      Other cookies data, including any targeting and marketing
                      cookies, will be processed subject to your consent,
                      provided through the cookie notice and Consent Management
                      Planform (<b>&quot;CMP&quot;</b>).
                    </p>
                    <p>
                      You may withdraw consent at any time by using the cookie
                      preference settings as detailed in the Cookie section
                      below.
                    </p>
                  </td>
                </tr>
              </table>
              <table
                /* className="privacy_table" */ style={{
                  width: "100%",
                  marginBottom: 20,
                }}
              >
                <tr>
                  <th>DATA SET</th>
                </tr>
                <tr>
                  <td>
                    <b>
                      <span style={{ textDecorationLine: "underline" }}>
                        Planning Your Trip Tool
                      </span>
                    </b>{" "}
                    <br />
                    <p>
                      when you access the Website or App and use our trip
                      planning tool provided therein, you will be required to
                      provide certain information including your destination and
                      travelling preferences such as trip styles (i.e., dynamic
                      or relaxed), length, etc.
                    </p>{" "}
                    <br />
                    <p>
                      at this stage, the Website does not require you to provide
                      any explicit identification information. However, the data
                      regarding your planned trip will be intertwined with
                      certain digital identifiers (i.e. IP, Cookie’s ID).
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>PURPOSE AND OPERATIONS</th>
                </tr>
                <tr>
                  <td>
                    <p>
                      We will use this data to assist you with planning your
                      trip and provide you with customized recommendations.
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>LAWFUL BASIS</th>
                </tr>
                <tr>
                  <td>
                    <p>
                      We process such data subject to our legitimate interests.
                    </p>
                  </td>
                </tr>
              </table>
              <table
                /* className="privacy_table" */ style={{
                  width: "100%",
                  marginBottom: 20,
                }}
              >
                <tr>
                  <th>DATA SET</th>
                </tr>
                <tr>
                  <td>
                    <b>
                      <span style={{ textDecorationLine: "underline" }}>
                        Subscribing and Sharing
                      </span>
                    </b>{" "}
                    <br />
                    <p>
                      In order to subscribe to our Services and receive the
                      information regarding your planned trip according to your
                      preferences, you will be required to create an account
                      with us by providing your contact information such as name
                      and email address, username and password.
                    </p>
                    <p>
                      After subscribing to our Services we will associate your
                      account and contact info with the trip information
                      gathered and your trip plan gathered before.
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>PURPOSE AND OPERATIONS</th>
                </tr>
                <tr>
                  <td>
                    <p>
                      We will use this data to provide you with the requested
                      Services, to allow you to manage your planned trips in the
                      Website and App and access your account.
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>LAWFUL BASIS</th>
                </tr>
                <tr>
                  <td>
                    <p>
                      We process such data subject to our contract with you.
                    </p>
                    <p>
                      Please note that in case you decide to register with your
                      existing social media accounts, such data will be
                      processed according to such platforms&#39; Privacy
                      Policies which we recommend you review (
                      <a
                        href="https://www.facebook.com/privacy/policy/"
                        target="blank"
                      >
                        Meta
                      </a>
                      ;
                      <a
                        href="https://policies.google.com/privacy?hl=en-US"
                        target="blank"
                      >
                        Google
                      </a>
                      ;
                      <a
                        href="https://www.apple.com/legal/privacy/en-ww/"
                        target="blank"
                      >
                        Apple
                      </a>
                      ).
                    </p>
                  </td>
                </tr>
              </table>
              <table
                /* className="privacy_table" */ style={{
                  width: "100%",
                  marginBottom: 20,
                }}
              >
                <tr>
                  <th>DATA SET</th>
                </tr>
                <tr>
                  <td>
                    <b>
                      <span style={{ textDecorationLine: "underline" }}>
                        Attractions and Local Heroes
                      </span>
                    </b>{" "}
                    <br />
                    <p>
                      In case you decide to reserve attractions or hosting
                      solutions with our local heroes or suppliers, you will be
                      required to provide us with your contact information and
                      your payment details.
                    </p>
                    <p>
                      {" "}
                      <b>
                        The payment is processed by our third-party partners; we
                        do not store or process any of credit card information.
                      </b>
                    </p>
                    <p>
                      If any of our plans will require any payment or
                      subscription, we will also collect, retain and process
                      your billing data, in a secure manner under common
                      standards.
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>PURPOSE AND OPERATIONS</th>
                </tr>
                <tr>
                  <td>
                    <p>
                      We will use this data to provide you with the requested
                      Services and allow you to purchase services and
                      attractions for your trip.
                    </p>
                    <p>
                      We will keep your data in our systems, including our
                      operational systems (e.g., ERP), use your transactional
                      data for clearance of payments and issuing invoices, and
                      keep record of your purchases.
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>LAWFUL BASIS</th>
                </tr>
                <tr>
                  <td>
                    <p>
                      We process such data subject to our contract with you.
                    </p>
                    <p>
                      After you will terminate the services, we will keep record
                      of your data as part of our legitimate interests.
                    </p>
                  </td>
                </tr>
              </table>
              <table
                /* className="privacy_table" */ style={{
                  width: "100%",
                  marginBottom: 20,
                }}
              >
                <tr>
                  <th>DATA SET</th>
                </tr>
                <tr>
                  <td>
                    <b>
                      <span style={{ textDecorationLine: "underline" }}>
                        Local Hero/Supplier Data
                      </span>
                    </b>{" "}
                    <br />
                    <p>
                      In order to become one of our local heroes or suppliers
                      which we recommend to our customers, you will be required
                      to provide your contact information such as your name,
                      email address, alongside details about the services which
                      you may propose to our users and any other information you
                      decide to share with us through the contact form.
                    </p>
                    <p>
                      {" "}
                      <b>
                        In addition, the Services will include a Review
                        mechanism as detailed below.
                      </b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>PURPOSE AND OPERATIONS</th>
                </tr>
                <tr>
                  <td>
                    <p>
                      We will use this data to provide you with the requested
                      Services.
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>LAWFUL BASIS</th>
                </tr>
                <tr>
                  <td>
                    <p>
                      We process such data subject to our contract with you.
                    </p>
                    <p>
                      Following the completion of the recruitment process, we
                      may further retain and store the data (including other
                      interactions with us under such process) as part of our
                      internal record keeping, including for legal defense from
                      any future claim, as well as, where we find applicable and
                      subject to applicable law requirements, to contact you in
                      the future for other offers we believe will suit you.
                    </p>
                  </td>
                </tr>
              </table>
            </div>
          ) : (
            <div className="privacy_table">
              <table style={{ width: "100%" }}>
                <tr>
                  <th>DATA SET</th>
                  <th>PURPOSE AND OPERATIONS</th>
                  <th>LAWFUL BASIS</th>
                </tr>
                <tr>
                  <td>
                    <b>
                      <span style={{ textDecorationLine: "underline" }}>
                        Contact Us
                      </span>
                    </b>
                    <p>
                      If you voluntarily contact us in order to ask for
                      information regarding our Services or any other inquiry,
                      you may be required to provide us with certain information
                      such as your name, email address, and additional
                      information you decide to share with us (
                      <b>"Contact Information"</b>).
                    </p>
                    <p>
                      If you are contacting us on behalf of another person, we
                      value your assistance and care for others, please note
                      that it is your responsibility to make sure that any
                      person whose Personal Data you provide is aware of the
                      principles of this statement and agrees that you will
                      provide Personal Data to us on this basis.
                    </p>
                  </td>
                  <td>
                    <p>
                      We will use this data to provide you with the information
                      you requested, respond to your inquiry, or provide our
                      Services. We may process the content of our correspondence
                      with you to improve customer service, and in the event we
                      believe it is required in order to provide you with any
                      further assistance (if applicable).
                    </p>
                    <p>
                      The correspondence and its contents with you may be
                      processed and stored by us in order to improve our
                      customer service and in the event, we believe it is
                      required to continue to store it, for example, in the
                      event of any claims or in order to provide you with any
                      further assistance (if applicable).
                    </p>
                  </td>
                  <td>
                    {" "}
                    We process such Contact Information and retain any inquiry
                    history subject to our legitimate interests.
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <span style={{ textDecorationLine: "underline" }}>
                        Online Identifiers
                      </span>
                    </b>
                    <p>
                      We use third-party cookies on the Website. As further
                      detailed below, these cookies provide us with analytic and
                      marketing services. The Personal Data processed is an
                      online identifier, either a cookie agent, the IP address,
                      etc. (<b>"Online Identifiers"</b>).
                    </p>
                  </td>
                  <td>
                    <p>
                      We will use your Online Identifiers for analytic and
                      marketing purposes.
                    </p>
                  </td>
                  <td>
                    <p>
                      Strictly necessary cookies which are required for the
                      proper and basic operation of the Website or App will be
                      processed in our legitimate interest.
                    </p>
                    <p>
                      Other cookies data, including any targeting and marketing
                      cookies, will be processed subject to your consent,
                      provided through the cookie notice and Consent Management
                      Planform (<b>&quot;CMP&quot;</b>).
                    </p>
                    <p>
                      You may withdraw consent at any time by using the cookie
                      preference settings as detailed in the Cookie section
                      below.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <span style={{ textDecorationLine: "underline" }}>
                        Planning Your Trip Tool
                      </span>
                    </b>{" "}
                    <br />
                    <p>
                      when you access the Website or App and use our trip
                      planning tool provided therein, you will be required to
                      provide certain information including your destination and
                      travelling preferences such as trip styles (i.e., dynamic
                      or relaxed), length, etc.
                    </p>{" "}
                    <br />
                    <p>
                      at this stage, the Website does not require you to provide
                      any explicit identification information. However, the data
                      regarding your planned trip will be intertwined with
                      certain digital identifiers (i.e. IP, Cookie’s ID).
                    </p>
                  </td>
                  <td>
                    <p>
                      We will use this data to assist you with planning your
                      trip and provide you with customized recommendations.
                    </p>
                  </td>
                  <td>
                    <p>
                      We process such data subject to our legitimate interests.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <span style={{ textDecorationLine: "underline" }}>
                        Subscribing and Sharing
                      </span>
                    </b>{" "}
                    <br />
                    <p>
                      In order to subscribe to our Services and receive the
                      information regarding your planned trip according to your
                      preferences, you will be required to create an account
                      with us by providing your contact information such as name
                      and email address, username and password.
                    </p>
                    <p>
                      After subscribing to our Services we will associate your
                      account and contact info with the trip information
                      gathered and your trip plan gathered before.
                    </p>
                  </td>
                  <td>
                    <p>
                      We will use this data to provide you with the requested
                      Services, to allow you to manage your planned trips in the
                      Website and App and access your account.
                    </p>
                  </td>
                  <td>
                    <p>
                      We process such data subject to our contract with you.
                    </p>
                    <p>
                      Please note that in case you decide to register with your
                      existing social media accounts, such data will be
                      processed according to such platforms&#39; Privacy
                      Policies which we recommend you review (
                      <a
                        href="https://www.facebook.com/privacy/policy/"
                        target="blank"
                      >
                        Meta
                      </a>
                      ;
                      <a
                        href="https://policies.google.com/privacy?hl=en-US"
                        target="blank"
                      >
                        Google
                      </a>
                      ;
                      <a
                        href="https://www.apple.com/legal/privacy/en-ww/"
                        target="blank"
                      >
                        Apple
                      </a>
                      ).
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <span style={{ textDecorationLine: "underline" }}>
                        Attractions and Local Heroes
                      </span>
                    </b>{" "}
                    <br />
                    <p>
                      In case you decide to reserve attractions or hosting
                      solutions with our local heroes or suppliers, you will be
                      required to provide us with your contact information and
                      your payment details.
                    </p>
                    <p>
                      {" "}
                      <b>
                        The payment is processed by our third-party partners; we
                        do not store or process any of credit card information.
                      </b>
                    </p>
                    <p>
                      If any of our plans will require any payment or
                      subscription, we will also collect, retain and process
                      your billing data, in a secure manner under common
                      standards.
                    </p>
                  </td>
                  <td>
                    <p>
                      We will use this data to provide you with the requested
                      Services and allow you to purchase services and
                      attractions for your trip.
                    </p>
                    <p>
                      We will keep your data in our systems, including our
                      operational systems (e.g., ERP), use your transactional
                      data for clearance of payments and issuing invoices, and
                      keep record of your purchases.
                    </p>
                  </td>
                  <td>
                    <p>
                      We process such data subject to our contract with you.
                    </p>
                    <p>
                      After you will terminate the services, we will keep record
                      of your data as part of our legitimate interests.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <span style={{ textDecorationLine: "underline" }}>
                        Local Hero/Supplier Data
                      </span>
                    </b>{" "}
                    <br />
                    <p>
                      In order to become one of our local heroes or suppliers
                      which we recommend to our customers, you will be required
                      to provide your contact information such as your name,
                      email address, alongside details about the services which
                      you may propose to our users and any other information you
                      decide to share with us through the contact form.
                    </p>
                    <p>
                      {" "}
                      <b>
                        In addition, the Services will include a Review
                        mechanism as detailed below.
                      </b>
                    </p>
                  </td>
                  <td>
                    <p>
                      We will use this data to provide you with the requested
                      Services.
                    </p>
                  </td>
                  <td>
                    <p>
                      We process such data subject to our contract with you.
                    </p>
                    <p>
                      Following the completion of the recruitment process, we
                      may further retain and store the data (including other
                      interactions with us under such process) as part of our
                      internal record keeping, including for legal defense from
                      any future claim, as well as, where we find applicable and
                      subject to applicable law requirements, to contact you in
                      the future for other offers we believe will suit you.
                    </p>
                  </td>
                </tr>
              </table>
            </div>
          )}
          <br />
          <p>
            Please note that the actual <b>processing operation</b> per each
            purpose of use and lawful basis detailed in the table above may
            differ. Such processing operation usually includes a set of
            operations, made by automated means, such as collection, storage,
            use, disclosure by transmission, erasure, or destruction. Transfer
            of personal data to third-party countries, as further detailed in
            the Data Transfer section, is based on the same lawful basis as
            stipulated in the table above.
          </p>
          <p>
            In addition, we may use certain Personal Data to prevent potentially
            prohibited or illegal activities, fraud, misappropriation,
            infringements, identity thefts, and any other misuse of the Website
            and App and to enforce the Terms, as well as to protect the security
            or integrity of our databases, Website, and App, and to take
            precautions against legal liability. Such processing is based on our
            legitimate interests.
          </p>
          <p>
            <b>Reviews and Ratings -</b> Website or App visitors and users of
            our services can leave a public review and submit a rating (Whether
            numerical, stars or any other method upon our sole discretion)
            regarding the services which they have purchased from any Local
            Hero/Supplier (<b>“Review”</b>). Reviews reflects the opinions of
            individual users and do not reflect our opinion. Reviews may be
            submitted directly by the users and are not verified by us
            regularly. If you believe that any Review relating to you is
            unjustified or offensive, please contact us and we will review the
            case on its merits.
          </p>
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>
            3){" "}
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              COOKIES USAGE
            </span>
          </span>
          <p>
            We use cookies to improve your experience while you interact with
            our Website and App and use the Services provided therein. We may
            use various types of Cookies:
          </p>
          <ul style={{ paddingLeft: 20 }}>
            <li style={{ fontSize: 12 }}>
              <b>Essential Cookies –</b> which are necessary for the Website to
              work properly;{" "}
            </li>
            <li style={{ fontSize: 12 }}>
              <b>Functional Cookies –</b> designated to save your settings on
              the Website - your language preference or other view preferences;
            </li>
            <li style={{ fontSize: 12 }}>
              <b>Session Cookies –</b> used to support the Website&#39;s
              functionality – such Cookies are stored only temporarily during a
              browsing session and are deleted from your device when you close
              the browser;
            </li>
            <li style={{ fontSize: 12 }}>
              <b>Targeting Cookies -</b> these cookies are used to collect
              information from you to help us improve our Services and provide
              you with targeted advertisements that we believe will be relevant
              to you (e.g., Google&#39;s Cookies).
            </li>
            <li style={{ fontSize: 12 }}>
              <b>Analytics Cookies -</b> give us aggregated and statistical
              information to improve the Services and further developing it
              (e.g., Google analytics, etc.).
            </li>
          </ul>
          <br />
          <p>
            Please note that the data collected by the use of Cookies may be
            linked to and combined with any other data, including Personal Data.
          </p>
          <p>
            In addition, Cookie&#39;s data is usually collected through the use
            of third-party services, like Google, Facebook, etc. In those cases,
            your Personal Data might be transferred to those third parties,
            which might link it and use it together with other information they
            have on you from other sources. Such data is &quot;owned&quot; and
            processed separately by those third-parties under their terms and
            conditions and the direct accounts or subscriptions you have with
            those third parties.
          </p>
        </p>
        <br />

        <p>
          <span style={{ fontWeight: "bold" }}>
            4){" "}
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              HOW WE COLLECT INFORMATION
            </span>
          </span>
          <p>
            According to the nature of your interaction with the Website and
            App, we may collect information as follows:
          </p>
          <ul style={{ paddingLeft: 20 }}>
            <li style={{ fontSize: 12 }}>
              <b>Automatically –</b> we may use cookies and similar tracking
              technologies (as elaborated in the Cookies Section below) to
              gather some information automatically when you interact with our
              Website or App.
            </li>
            <li style={{ fontSize: 12 }}>
              <b>Provided by you voluntarily –</b> we will collect information
              if and when you choose to provide us with information, such as
              when you contact us in case of an inquiry or planning a trip with
              us as part of the Services.
            </li>
            <li style={{ fontSize: 12 }}>
              <b>By other users –</b> as detailed in the Rating section above.
            </li>
          </ul>
        </p>

        <p>
          <span style={{ fontWeight: "bold" }}>
            5){" "}
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              DATA SHARING– CATEGORIES OF RECIPIENTS WE SHARE PERSONAL DATA WITH
            </span>
          </span>
          <p>
            We share your data with third parties, including trusted partners or
            service providers that help us provide our Services and improve the
            Website and App:
          </p>
          <ul style={{ paddingLeft: 20 }}>
            <li style={{ fontSize: 12 }}>
              <span style={{ textDecorationLine: "underline" }}>
                Legal Requirement:
              </span>
               We will share your information in this situation only if we are
              required to do so to comply with any applicable law, regulation,
              legal process, or governmental request (e.g., to comply with a
              court injunction, comply with tax authorities, etc.);
            </li>
            <li style={{ fontSize: 12 }}>
              <span style={{ textDecorationLine: "underline" }}>
                Policy Enforcement:
              </span>
               We will share your information, solely to the extent required to
              (i) enforce our policies and agreements; or (ii) to investigate
              any potential violations thereof, including without limitations,
              detect, prevent, or take action regarding illegal activities or
              other wrongdoings, suspected fraud or security issues;
            </li>
            <li style={{ fontSize: 12 }}>
              <span style={{ textDecorationLine: "underline" }}>
                Company&#39;s Rights:
              </span>{" "}
              We will share your information to establish or exercise our
              rights, to prevent harm to our rights, property, or safety, and to
              defend ourselves against legal claims, when necessary, subject to
              applicable law;
            </li>
            <li style={{ fontSize: 12 }}>
              <span style={{ textDecorationLine: "underline" }}>
                Third Party Rights:
              </span>{" "}
              We will share your information, solely to the extent required to
              prevent harm to the rights of our customers, yourself, or any
              third party&#39;s rights, property, or safety;
            </li>
            <li style={{ fontSize: 12 }}>
              <span style={{ textDecorationLine: "underline" }}>
                Service Providers:
              </span>
              we share your information with third parties that perform services
              on our behalf (e.g., tracking cookies, servers, marketing, and
              support, etc.) these third parties may be located in different
              jurisdictions.
            </li>
            <li style={{ fontSize: 12 }}>
              <span style={{ textDecorationLine: "underline" }}>
                Corporate Transaction:
              </span>
              we may share your information in the event of a corporate
              transaction (e.g., sale of a substantial part of our business,
              merger, consolidation or asset sale). In the event of the above,
              our affiliated companies or acquiring Company will assume the
              rights and obligations as described in this Privacy Policy;
            </li>
            <li style={{ fontSize: 12 }}>
              <span style={{ textDecorationLine: "underline" }}>
                Authorized Disclosures:
              </span>
              we may disclose your information to third parties when you consent
              to a particular disclosure. Please note that once we share your
              information with another company, that information becomes subject
              to the other Company&#39;s privacy practices.
            </li>
          </ul>
          <p>
            Where we share information with service providers and partners, we
            ensure they only have access to such information that is strictly
            necessary in order for us to provide the Services. These parties are
            required to secure the data they receive and to use the data for
            pre-agreed purposes only, while ensuring compliance with all
            applicable data protection regulations.
          </p>
        </p>
        <br />
        <p>
          <span style={{ fontWeight: "bold" }}>
            6){" "}
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              DATA TRANSFER
            </span>
          </span>
          <p>
            Any information you provide us may be transferred to and processed
            in countries other than the country from which you accessed the
            Website or App. If you are a resident of the European Economic Area
            (<b>&quot;EEA&quot;</b>) we will take appropriate measures to ensure
            that your Personal Data receives an adequate level of data
            protection upon its transfer outside of the EEA. If you are a
            resident of a jurisdiction where the transferring of your Personal
            Data requires your consent, then your consent to this Privacy Policy
            includes your express consent for such data transfer.
          </p>
        </p>
        <br />
        <p>
          <span style={{ fontWeight: "bold" }}>
            7){" "}
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              USER RIGHTS
            </span>
          </span>
          <p>
            We acknowledge that different people have different privacy concerns
            and preferences. Our goal is to be clear about what information we
            collect so that you can make meaningful choices about how it is
            used. We provide you with the ability to exercise certain choices,
            rights, and controls in connection with your information. Depending
            on your relationship with Indie4x4, data protection and privacy
            laws provide you with some of the following principal rights
            regarding your Personal Data, including (and depending on your
            jurisdiction): The right to access your Personal Data that we
            process; The right to ensure your Personal Data is accurate,
            complete and up to date; The right to have your Personal Data
            amended (by correcting, deleting or adding information); The right
            to object to the processing of your Personal Data, to the extent
            applicable; The right to send or &quot;port&quot; your Personal
            Data; The right to file a complaint with a supervisory authority in
            your jurisdiction; The right to withdraw consent, subject to legal
            or contractual restrictions and reasonable notice; etc.
          </p>
          <p>
            You may exercise any or all of your above rights in relation to your
            Personal Data by contacting us at:{" "}
            <a href="mailto: support@Indie4x4.com">
              support@Indie4x4.com.
            </a>
          </p>
          <p>
            Where we are not able to provide you with the information for which
            you have asked, we will endeavor to explain the reasoning for this
            and inform you of your rights, including the right to complain to
            the supervisor authority (in the event you are an EEA resident). We
            reserve the right to ask for reasonable evidence to verify your
            identity before we provide you with any such information in
            accordance with applicable law.
          </p>
        </p>
        <br />
        <p>
          <span style={{ fontWeight: "bold" }}>
            8){" "}
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              RETENTION
            </span>
          </span>
          <p>
            We retain Personal Data we collect as long as it remains necessary
            for the purposes set forth above, all in accordance with applicable
            laws, or until an individual expresses a preference to opt-out.
          </p>
          <p>
            Other circumstances in which we will retain your Personal Data for
            longer periods of time include: (i) where we are required to do so
            in accordance with legal, regulatory, tax, or
            accounting requirements; (ii) for us to have an accurate record of
            your dealings with us in the event of any complaints or challenges;
            or (iii) if we reasonably believe there is a prospect of litigation
            relating to your Personal Data. Please note that except as required
            by applicable law, we may at our sole discretion, delete or amend
            information from our systems, without notice to you, once we deem it
            is no longer necessary for such purposes.
          </p>
        </p>
        <br />
        <p>
          <span style={{ fontWeight: "bold" }}>
            9){" "}
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              SECURITY
            </span>
          </span>
          <p>
            We implement extensive security measures to reduce the risks of
            damage, loss of information, and unauthorized access or misuse of
            Personal Data. We implement appropriate data collection, storage,
            and processing practices and security tools to protect Personal Data
            against unauthorized access, alteration, disclosure, or destruction.
            You should be aware that no security measures are completely
            fail-proof, and it is impossible to prevent any and all threats to
            the security of data and systems. Therefore, you should be aware
            that any processing of digital Personal Data holds certain inherent
            risks, and we cannot guarantee that our services and databases will
            be immune to any wrongdoings, malfunctions, unauthorized
            interceptions or access, malware attacks or other kinds of abuse and
            misuse.
          </p>
        </p>
        <br />
        <p>
          <span style={{ fontWeight: "bold" }}>
            10){" "}
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              CHILDREN
            </span>
          </span>
          <p>
            Our Services are not intended for use by children and we do not
            knowingly collect or maintain information about anyone under the age
            of 16. Please contact us at:
            <a href="mailto: support@Indie4x4.com">
              support@Indie4x4.com
            </a>{" "}
            if you have reason to believe that a child has shared any
            information with us.
          </p>
        </p>
      </Content>

<Footer/>
    </>
  );
};

export default PrivacyPolicy;
