import React, { useState, useEffect, useRef } from "react";
import "./LandingPage.css";
import PowerOfTeamSliderNew from "./PowerOfTeamSliderNew";

const LocalExpert = () => {

    const [mobileView, setMobileView] = useState(window.innerWidth <= 768);

    useEffect(() => {
      function updateSize() {
        if (window.innerWidth <= 768) setMobileView(true);
        else setMobileView(false);
      }
      window.addEventListener("resize", updateSize);
    }, []);

    return (
        <div className="localExpertMainContainer">
        <div className="localExpertInnerContainer">
           <div className="localExpertMainHead">
           Our local expert community
           </div>
           <div className="localExpertShortDes">
           Our knowledgeable Local Experts are passionate about assisting others. Feel {!mobileView && <br />} free to reach out anytime with any questions or needs; they'll be there to advise, {!mobileView && <br />} arrange, aid, and suggest—whatever you require!
           </div>
           <PowerOfTeamSliderNew />
           <div className="localExpertBtn clickableBtn">
           Meet Our Local Experts
           </div>
        </div>
        </div>
    )
}
export default LocalExpert;
