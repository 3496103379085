import React, { useState, useEffect, useRef } from "react";
import "./ContactUs.css";
import { useNavigate, useLocation } from 'react-router-dom';
import VideoPage from "../LandingFolder/VideoSection";
import BlackEnvelopeIcon from "../../assets/blackEnvelopeIcon.png"
import FbBlackIcon from "../../assets/fbBlackIcon.png"
import BlackWhatsappIcon from "../../assets/blackWhatsappIcon.png"
import DropDownArrow from "../../assets/dropDownArrow.png"
import GallerySection from "../Destinations/GallerySection";
import { BASE_URL, get, post } from "../../services/Calls";
import AuthLoader from '../../assets/authLoader.gif'
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { ToastContainer, toast } from 'react-toastify';
import Header from "../../GeneralComponents/Header";
import Loader from "../../GeneralComponents/Loader";
import Footer from "../../GeneralComponents/Footer";

const ContactUs = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [errorMsg, setErrorMsg] = useState("")
    const [loader, setLoader] = useState(false)
    const [countryData, setCountryData]: any = useState([])
    const [country, setCountry]: any = useState({})
    const [showCountrySelect, setShowCountrySelect] = useState(false);

    const [scrollY, setScrollY] = useState(0);
    const [mobileView, setMobileView] = useState(window.innerWidth <= 768);

    useEffect(() => {
      function updateSize() {
        if (window.innerWidth <= 768) setMobileView(true);
        else setMobileView(false);
      }
      window.addEventListener("resize", updateSize);
    }, []);

 
  
    // useEffect(() => {
    //   function updateScroll() {
    //     setScrollY(window.scrollY);
    //   }
    //   window.addEventListener('scroll', updateScroll);

    // }, []); 


    useEffect(() => {
        getCountries()
        window.scrollTo(0, 0);
    }, []);
    const getCountries = async () => {
        try{
        const response = await get(BASE_URL + '/countries?order=ASC')
        if (response && response.length > 0) {
            setCountryData(response)
        }
    }
    catch(ex){
        //alert(ex)
console.log('error',ex)
    }
    }


    const handleSubmit = async (event: any) => {

        event.preventDefault();
        const formData = event.currentTarget.elements;

        try {
            if (formData[0].value === '') {
                setErrorMsg("Name can't be empty.")
                return;
            }
            if (formData[1].value === '') {
                setErrorMsg("Email can't be empty.")
                return;
            }
            if (formData[1].value !== "") {
                let pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                if (!pattern.test(formData[1].value)) {
                    setErrorMsg("Invalid email format.");
                    return;
                }
            }
            if (formData[2].value === '') {
                setErrorMsg("Phone can't be empty.")
                return;
            }
            if (country && country.id) {

            }
            else {
                setErrorMsg("Please Select Destination.")
                return;
            }

            let tempEmail = formData[1].value
            tempEmail = tempEmail.toLowerCase();
            const data = {
                "name": formData[0].value,
                "email": tempEmail,
                "phone": formData[2].value,
                "destination": country.name
            };
            setLoader(true)
            // setTimeout(() => {
            //     setLoader(false)
            //     navigate('/')
            // }, 5000);
              const response = await post(BASE_URL + '/contact/contact_us', data)
              if (response && response.data && response.data.status == 'ok') {
                  setCountry({})
                notify()  
                setLoader(false);
                navigate('/')
              }
              else{
                setLoader(false);
                setErrorMsg('Something went wrong, please try again later.')
              }
        } catch (ex:any) {
            setLoader(false);
            setErrorMsg(ex);
        }

    };

    const notify = () => {
        toast.success('Thanking you for contacting us.', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    };

    return (
        <div>
            <Header/>
            <VideoPage pageFrom={'contactUs'} countryname={""} />
            <div className="destinationPathRow" id='contactUsPageId'>
                <div className="destinationPaginationDiv">
                    <span className="destinationPaginationHome" onClick={() => {navigate('/')}} >Home</span>
                    <span className="destinationPaginationBorder"></span>
                    <span className="destinationPaginationCountry">Contact Us</span>
                </div>
                <div className="destinationSocialIconDiv">
                    <img className="destinationSocialFbIcon" src={FbBlackIcon} />
                    <img className="destinationSocialMailIcon" src={BlackEnvelopeIcon} />
                    <img className="destinationSocialWhatsAppIcon" src={BlackWhatsappIcon} />
                </div>
            </div>

<div /* className={`${scrollY>200?"wow bounceInUp":""}`} */ className="wow bounceInUp">
            <div className="ContactUsFormDiv">
                <form onSubmit={handleSubmit} >
                    <div className='contactUsInputCss'>
                        <input
                            type="text"
                            name="name"
                            placeholder='Name'
                            onFocus={() => { setErrorMsg('') }}
                        />
                    </div>
                    <div className='contactUsInputCss'>
                        <input
                            type="email"
                            name="email"
                            placeholder='Email'
                            onFocus={() => { setErrorMsg('') }}
                        />
                    </div>

                    <div className='contactUsInputCss'>
                        <input
                            type="number"
                            name="phone"
                            placeholder='Phone'
                            onFocus={() => { setErrorMsg('') }}
                        />
                    </div>

                    <div className='contactUsInputCss' style={{ cursor: 'pointer', justifyContent: 'flex-start', alignItems: 'flex-start' }} onClick={() => { setShowCountrySelect(!showCountrySelect); setErrorMsg("") }}>

                        <div className='InputBoxCss' style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                            {country && country.id ?
                                <span style={{ color: '#000', fontSize:mobileView?"3.5vw":'1.08vw' }}>{country.name}</span>
                                :
                                <span style={{ color: 'gray', fontSize:mobileView?"3.5vw":'1.08vw' }}>Destination</span>
                            }
                            <img src={DropDownArrow} style={{ width: mobileView?"3.5vw":'1vw', height: mobileView?"3.5vw":'1vw', transform: showCountrySelect ? "rotate(270deg)" : 'rotate(90deg)' }} />
                        </div>

                    </div>
                    {showCountrySelect &&
                        <ClickAwayListener onClickAway={() => { setShowCountrySelect(false) }}>
                            <div className="dropDownDiv">
                                {countryData && countryData.length > 0 && countryData.map((item: any, index: any) => (
                                    <div className="countryDropDownItemDiv" onClick={() => { setCountry(item); setShowCountrySelect(false) }}>
                                        {item.name}
                                    </div>
                                ))}
                            </div>
                        </ClickAwayListener>
                    }
                    {errorMsg != '' && (
                        <div
                            style={{
                                textAlign: 'center',
                                color: 'red',
                                fontSize: mobileView?"3.5vw":'1vw',
                            }}
                        >
                            {errorMsg}
                        </div>
                    )}
                    <div className='contactUsPageBtnUpperDiv' >
                        <button type="submit" className='contactUsPageBtn'>Contact</button>
                    </div>
                </form>
            </div>
            </div>
            <div style={{ height: '6.625vw' }}></div>
            {loader &&
                <Loader/>
            }
            <Footer/>
        </div>
    )
}
export default ContactUs;
