import React, { useEffect, useState } from 'react';
import "./MapBox.css";
import mapboxgl from 'mapbox-gl';
import ZoomInIcon from '../../../assets/zoomInIcon.png'
import ZoomOutIcon from '../../../assets/zoomOutIcon.png'

// Replace 'YOUR_MAPBOX_ACCESS_TOKEN' with your actual Mapbox access token
mapboxgl.accessToken = 'pk.eyJ1IjoidXJpYWJyaWRnaWZ5IiwiYSI6ImNrb3IybjRtYjEwd2wydnBpdGxvNWVoYzIifQ.jUaSkJhni2GW103YouzXOQ';

interface IProps {
  tripsData: any;
}


const MapBox = ({ tripsData }: IProps) => {
  const [map, setMap]: any = useState(null);
  const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v11');
  const [mapMode, setMapMode] = useState('1');
  const [mobileView, setMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 768) setMobileView(true);
      else setMobileView(false);
    }
    window.addEventListener("resize", updateSize);
  }, []);
  useEffect(() => {
    console.log("days",tripsData)

    const newMap: any = new mapboxgl.Map({
      container: 'map', 
      style: mapStyle, 
      center: tripsData && tripsData.days && tripsData.days.length>0 ? [tripsData.days[0].start_longitude, tripsData.days[0].end_latitude] : [-74.5, 40], 
      zoom: 5, 
    });
    newMap.on('load', () => {
      setMap(newMap);
    });


    let coordinates:any = [];

    // Add a marker for the start location
    if(tripsData && tripsData.days && tripsData.days.length>0){
      for(let i=0;i<tripsData.days.length;i++){
    new mapboxgl.Marker()
      .setLngLat([tripsData.days[i].start_longitude, tripsData.days[i].start_latitude])
      .addTo(newMap);

    new mapboxgl.Marker()
      .setLngLat([tripsData.days[i].end_longitude, tripsData.days[i].end_latitude])
      .addTo(newMap);

      coordinates.push([tripsData.days[i].start_longitude, tripsData.days[i].start_latitude],[tripsData.days[i].end_longitude, tripsData.days[i].end_latitude])
  }
   const bounds = coordinates.reduce((bbox:any, coord:any) => {
    return bbox.extend(coord);
  }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

  newMap.fitBounds(bounds, { padding: { left: 100, top: 60, right: 100, bottom: 60 }});
  }

    return () => newMap.remove();
  }, [mapStyle, mapMode]); 

  const switchToSatellite = () => {
    setMapStyle('mapbox://styles/mapbox/satellite-v9');
    setMapMode('2')
  };

  const switchToStreets = () => {
    setMapStyle('mapbox://styles/mapbox/streets-v11');
    setMapMode('1')
  };

  const handleZoomIn = () => {
    if (map) {
      const currentZoom = map.getZoom();
      map.setZoom(currentZoom + 0.5);
    }
  };

  const handleZoomOut = () => {
    if (map) {
      const currentZoom = map.getZoom();
      map.setZoom(currentZoom - 0.5);
    }
  };

  return (
    <div style={{ height: '100%' }}>
      <div className='mapBoxSwitchBtnDiv'>
        <button className='mapBoxSwitchBtn1' onClick={switchToSatellite} style={{ color: mapMode == '1' ? '#565656' : '#000' }}>Satellite</button>
        <button className='mapBoxSwitchBtn2' onClick={switchToStreets} style={{ color: mapMode != '1' ? '#565656' : '#000' }}>Map</button>
      </div>

      <div id="map" style={{ width: '100%', height: '100%' }}></div>
      <div className='mapBoxZoomBtnContainerMainDiv'>
        <div className='mapBoxZoomBtnContainer'>
          <button onClick={handleZoomIn} style={{ borderBottom: mobileView ? '0.5vw solid #E6E6E6' : '0.0625vw solid #E6E6E6' }}><img src={ZoomInIcon} className='mapZoomInIcon' /> </button>
          <button onClick={handleZoomOut}><img src={ZoomOutIcon} className='mapZoomOutIcon' /></button>
        </div>
      </div>
    </div>
  )
};

export default MapBox;
