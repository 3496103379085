import React, { useState, useEffect, useRef } from "react";
import "./SelfDrive.css";
import { useNavigate, useLocation } from 'react-router-dom';
import VideoPage from "../LandingFolder/VideoSection";
import BlackEnvelopeIcon from "../../assets/blackEnvelopeIcon.png"
import FbBlackIcon from "../../assets/fbBlackIcon.png"
import BlackWhatsappIcon from "../../assets/blackWhatsappIcon.png"
import GallerySection from "../Destinations/GallerySection";
import Header from "../../GeneralComponents/Header";
import Footer from "../../GeneralComponents/Footer";

const SelfDrive = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [countryData, setCountryData]: any = useState({})

    useEffect(() => {
        if (location && location.state && location.state.country) {
            setCountryData(location.state.country)
        }
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>
            <Header/>
            <VideoPage pageFrom={'slefDrive'} countryname={""} />
            <div className="destinationPathRow" id="selfDrivePageId">
  <div className="destinationPaginationDiv">
        <span className="destinationPaginationHome" onClick={()=>{navigate('/')}} >Home</span>
        <span className="destinationPaginationBorder"></span>
        <span className="destinationPaginationCountry">Bulgaria</span>
    </div>
    <div className="destinationSocialIconDiv">
        <img className="destinationSocialFbIcon" src={FbBlackIcon} />
        <img className="destinationSocialMailIcon" src={BlackEnvelopeIcon} />
        <img className="destinationSocialWhatsAppIcon" src={BlackWhatsappIcon} />
    </div>
        </div>

            <div className="selfDriveBenefitsDiv">

<div className="selfDriveBenefitsMainHead">
Self-drive 4x4 travel offers several benefits
</div>
<div className="selfDriveBenefitsItems">
<div>
    <span className="selfDriveBenefitsHead">Flexibility and Independence:</span>&nbsp;
    <span className="selfDriveBenefitsDes">You have the freedom to create your itinerary, stop wherever you want, and explore off-the-beaten-path destinations at your own pace.</span>
</div>
<div>
    <span className="selfDriveBenefitsHead">Access to Remote Areas:</span>&nbsp;
    <span className="selfDriveBenefitsDes">With a 4x4 vehicle, you can access rugged terrains, remote areas, and unpaved roads that are inaccessible by regular vehicles, allowing for a more adventurous exploration.</span>
</div>
<div>
    <span className="selfDriveBenefitsHead">Closer to Nature:</span>&nbsp;
    <span className="selfDriveBenefitsDes">It brings you closer to nature, enabling you to experience landscapes, wildlife, and natural wonders that might not be reachable through conventional transportation.</span>
</div>
<div>
    <span className="selfDriveBenefitsHead">Personalized Experience:</span>&nbsp;
    <span className="selfDriveBenefitsDes">You can tailor your trip according to your preferences, spending more time at places that interest you and changing plans on the go.</span>
</div>
<div>
    <span className="selfDriveBenefitsHead">Adventure and Thrill:</span>&nbsp;
    <span className="selfDriveBenefitsDes">Driving a 4x4 vehicle itself can be an exhilarating experience, especially on challenging terrains, providing a sense of adventure and thrill.</span>
</div>
<div>
    <span className="selfDriveBenefitsHead">Privacy and Exclusivity:</span>&nbsp;
    <span className="selfDriveBenefitsDes">You have the privacy of your own vehicle, offering a more intimate and exclusive travel experience, away from crowded tourist spots.</span>
</div>
<div>
    <span className="selfDriveBenefitsHead">Learning and Skill Development:</span>&nbsp;
    <span className="selfDriveBenefitsDes">It allows you to learn new driving skills, navigation techniques, and provides opportunities for self-reliance and problem-solving.</span>
</div>
<div>
    <span className="selfDriveBenefitsHead">Photography and Exploration:</span>&nbsp;
    <span className="selfDriveBenefitsDes">Self-drive 4x4 travel offers better opportunities for photography, exploration, and discovery, capturing unique moments and sights along the journey.</span>
</div>
<div>
    <span className="selfDriveBenefitsDes">Remember, while self-drive 4x4 travel can be immensely rewarding, it's essential to be adequately prepared, have necessary permits, understand the local driving rules, and ensure safety measures are in place, especially when venturing into remote areas.</span>
</div>
</div>

            </div>

            <GallerySection />
            <div style={{ height: '6.625vw' }}></div>
            <Footer/>
        </div>
    )
}
export default SelfDrive;
