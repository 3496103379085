import axios from "axios";


export const BASE_URL = "https://indie4x4-backend-9bff9eabafde.herokuapp.com/api/v1";
const _config = {};

// export async function put(url:any, data:any) {
//   if (
//     localStorage.getItem("token") !== null &&
//     localStorage.getItem("token") !== undefined
//   ) {
//     axios.defaults.headers.common[
//       "authorization"
//     ] = `Bearer ${localStorage.getItem("token")}`;
//   }
//   try{
//   const response = await axios.put(url, data, _config);
//   return response;
//   }
//   catch (error) {
//     // sendmail(url)
//     throw new Error("api error");
//   }
// }

export async function post(url:any, data:any) {
  // if (
  //   localStorage.getItem("token") !== null &&
  //   localStorage.getItem("token") !== undefined
  // ) {
  //   axios.defaults.headers.common[
  //     "authorization"
  //   ] = `Bearer ${localStorage.getItem("token")}`;
  // }
    const response = await axios.post(url, data);
    return response;
  }
export async function login(url:any, data:any) {

    const response = await axios.post(url, data);
    if(response)
   return {status:'ok'}
  else
  return {status:'fail'}
  }
export async function DeleteUser(url:any) {

    const response = await axios.delete(url);
  
   return {status:'ok'}
  }





  export async function get(url:any, config = {}) {
    if (
      localStorage.getItem("token") !== null &&
      localStorage.getItem("token") !== undefined
    ) {
      axios.defaults.headers.common[
        "authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
    }
    config = {
      ..._config,
      ...config,
    };
    try {
      const response = await axios.get(url, config);
      return response.data;
    } catch (error) {
  
      throw new Error("api error");
    }
  }

  // export async function deleteCall(url:any, config = {}) {
  //   if (
  //     localStorage.getItem("token") !== null &&
  //     localStorage.getItem("token") !== undefined
  //   ) {
  //     axios.defaults.headers.common[
  //       "authorization"
  //     ] = `Bearer ${localStorage.getItem("token")}`;
  //   }
  //   config = {
  //     ..._config,
  //     ...config,
  //   };
  //   try {
  //     const response = await axios.delete(url, config);
  //     return response.data;
  //   } catch (error) {
  
  //     throw new Error("api error");
  //   }
  // }