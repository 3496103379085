import React, { useState,useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

const Extra = () => {
  const [isAnimated, setIsAnimated] = useState(false);

  // Define the animation properties using useSpring
  const animation = useSpring({
    opacity: isAnimated ? 1 : 0,
    transform: `scale(${isAnimated ? 1 : 0.5})`,
  });

  const handleButtonClick = () => {
    setIsAnimated(!isAnimated);
  };
  const [graphdata,setGraphData]=useState([])
const data:any=[
  [
      {
          "id": "23034686",
          "platform": "mt5",
          "type": "DEAL_TYPE_SELL",
          "time": "2024-10-17T12:57:47.581Z",
          "brokerTime": "2024-10-17 15:57:47.581",
          "commission": -5.04,
          "swap": 0,
          "profit": 0,
          "symbol": "XAUEUR.PRO",
          "magic": 0,
          "orderId": "34817119",
          "positionId": "34817119",
          "volume": 0.72,
          "price": 2473.1,
          "entryType": "DEAL_ENTRY_IN",
          "reason": "DEAL_REASON_EXPERT",
          "takeProfit": 2463.12,
          "brokerComment": "Safety Order",
          "comment": "Safety Order",
          "accountCurrencyExchangeRate": 1,
          "tProfit": "0.00",
          "tTrades": 1
      }
  ],
  [
      {
          "id": "23035806",
          "platform": "mt5",
          "type": "DEAL_TYPE_SELL",
          "time": "2024-10-17T13:06:17.214Z",
          "brokerTime": "2024-10-17 16:06:17.214",
          "commission": -6.02,
          "swap": 0,
          "profit": 0,
          "symbol": "XAUEUR.PRO",
          "magic": 0,
          "orderId": "34817946",
          "positionId": "34817946",
          "volume": 0.86,
          "price": 2474.11,
          "entryType": "DEAL_ENTRY_IN",
          "reason": "DEAL_REASON_EXPERT",
          "takeProfit": 2464.11,
          "brokerComment": "Safety Order",
          "comment": "Safety Order",
          "accountCurrencyExchangeRate": 1,
          "isShow": false,
          "collapseIndex": "0,1",
          "tProfit": "0.00",
          "tTrades": 2
      },
      {
          "id": "23035841",
          "platform": "mt5",
          "type": "DEAL_TYPE_SELL",
          "time": "2024-10-17T13:06:56.348Z",
          "brokerTime": "2024-10-17 16:06:56.348",
          "commission": -7.28,
          "swap": 0,
          "profit": 0,
          "symbol": "XAUUSD.PRO",
          "magic": 0,
          "orderId": "34817978",
          "positionId": "34817978",
          "volume": 1.04,
          "price": 2681.9,
          "entryType": "DEAL_ENTRY_IN",
          "reason": "DEAL_REASON_EXPERT",
          "takeProfit": 2671.93,
          "brokerComment": "Safety Order",
          "comment": "Safety Order",
          "accountCurrencyExchangeRate": 1
      }
  ],
  [
      {
          "id": "23035879",
          "platform": "mt5",
          "type": "DEAL_TYPE_SELL",
          "time": "2024-10-17T13:07:40.157Z",
          "brokerTime": "2024-10-17 16:07:40.157",
          "commission": -7.28,
          "swap": 0,
          "profit": 0,
          "symbol": "XAUEUR.PRO",
          "magic": 0,
          "orderId": "34818014",
          "positionId": "34818014",
          "volume": 1.04,
          "price": 2475.24,
          "entryType": "DEAL_ENTRY_IN",
          "reason": "DEAL_REASON_EXPERT",
          "takeProfit": 2465.15,
          "brokerComment": "Safety Order",
          "comment": "Safety Order",
          "accountCurrencyExchangeRate": 1,
          "tProfit": "0.00",
          "tTrades": 1
      }
  ],
  [
      {
          "id": "23036199",
          "platform": "mt5",
          "type": "DEAL_TYPE_SELL",
          "time": "2024-10-17T13:08:27.755Z",
          "brokerTime": "2024-10-17 16:08:27.755",
          "commission": -8.68,
          "swap": 0,
          "profit": 0,
          "symbol": "XAUEUR.PRO",
          "magic": 0,
          "orderId": "34818244",
          "positionId": "34818244",
          "volume": 1.24,
          "price": 2476.26,
          "entryType": "DEAL_ENTRY_IN",
          "reason": "DEAL_REASON_EXPERT",
          "takeProfit": 2466.26,
          "brokerComment": "Safety Order",
          "comment": "Safety Order",
          "accountCurrencyExchangeRate": 1,
          "isShow": false,
          "collapseIndex": "0,1,2,3",
          "tProfit": "0.00",
          "tTrades": 2
      },
      {
          "id": "23036347",
          "platform": "mt5",
          "type": "DEAL_TYPE_SELL",
          "time": "2024-10-17T13:08:59.598Z",
          "brokerTime": "2024-10-17 16:08:59.598",
          "commission": -10.43,
          "swap": 0,
          "profit": 0,
          "symbol": "XAUEUR.PRO",
          "magic": 0,
          "orderId": "34818384",
          "positionId": "34818384",
          "volume": 1.49,
          "price": 2477.26,
          "entryType": "DEAL_ENTRY_IN",
          "reason": "DEAL_REASON_EXPERT",
          "takeProfit": 2467.27,
          "brokerComment": "Safety Order",
          "comment": "Safety Order",
          "accountCurrencyExchangeRate": 1
      }
  ]
 
 
]




  useEffect(()=>{
  setGraphData(data);
  },[])

  const [sortKey, setSortKey] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const sortdata = (key:any) => {
    const sortedData = [...graphdata].sort((a, b) => {
      const aValue = a[0][key];
      const bValue = b[0][key];

      if (sortOrder === 'asc' ) {
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
        return 0;
      } else {
        if (aValue > bValue) return -1;
        if (aValue < bValue) return 1;
        return 0;
      }
    });

    setGraphData(sortedData);
    setSortKey(key);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  return (
    <div>
  
  <table
                style={{
                  width: "100%",
                  marginBottom: 20,
                }}
              >
                <tr>
                  <th onClick={()=>{sortdata('id')}}>id</th>
                  <th onClick={()=>{sortdata('time')}}>time</th>
                  <th onClick={()=>{sortdata('commission')}}>commission</th>
                  <th onClick={()=>{sortdata('orderId')}}>orderId</th>
                  <th onClick={()=>{sortdata('entryType')}}>entryType</th>
                  <th onClick={()=>{sortdata('takeProfit')}}>takeProfit</th>
                </tr>
{graphdata.map((data1: any, index: any) => (

data1.map((data2: any, index: any) => (

  <tr>
                  <th >{data2.id}</th>
                  <th>{data2.time}</th>
                  <th>{data2.commission}</th>
                  <th>{data2.orderId}</th>
                  <th>{data2.entryType}</th>
                  <th>{data2.takeProfit}</th>
                 
                </tr>
))
)
)

}
</table>
    </div>
  );
};

export default Extra;
