import React, { useState, useEffect, useRef } from "react";
import "./LandingPage.css";
import VideoPage from "./VideoSection";
import MobileWithHand from "../../assets/mobile_with_hand.png"
import DestinationsSection from "./DestinationsSection";
import MostPopular from "./MostPopular";
import IndieGuide from "./IndieGuide";
import Headline from "./Headline";
import TravelWithIndie from "./TravelWithIndie";
import Care from "./Care";
import LocalExpert from "./LocalExpert";
import ShareYourDetails from "./ShareYourDetails";
import { useSpring, animated } from 'react-spring';
import { BASE_URL, get } from "../../services/Calls";
import Header from "../../GeneralComponents/Header";
import Footer from "../../GeneralComponents/Footer";

const LandingPage = () => {


  const [mobileView, setMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 768) setMobileView(true);
      else setMobileView(false);
    }
    window.addEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


 

  useEffect(() => {
    function updateScroll() {
      if(window.scrollY > 300){
          setIsAnimated(true);
      }
      else{
        setIsAnimated(false);
      }
      if(window.scrollY > 4800){
        setIsAnimatedFlip(true);
      }
      else{
        setIsAnimatedFlip(false);
      }
    }
    window.addEventListener('scroll', updateScroll);

  }, []); 


  const [isAnimated, setIsAnimated] = useState(false);
  const [isAnimatedFlip, setIsAnimatedFlip] = useState(false);

// Define the animation properties using useSpring
const animation = useSpring({
  opacity: isAnimated ? 1 : 0,
  transform: `scale(${isAnimated ? 1 : 0.5})`,
});

const animationFlip = useSpring({
  opacity: isAnimatedFlip ? 1 : 0,
  transform: `rotateX(${isAnimatedFlip ? '0deg' : '180deg'})`,
});

  return (
    <div>
      <Header/>
      <VideoPage pageFrom={'landing'} countryname={""} />
      {!mobileView &&
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', marginTop: "-23.875vw" }}>
          <img src={MobileWithHand} style={{ width: '22.375vw', height: '30.125vw', position: 'relative', zIndex: 1 }} />
        </div>
      }
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: "0.3125vw", marginTop: mobileView ? '2vw' : "-5vw" }} id="landingPageId">
        {!mobileView ?
          <>
            <div style={{ color: '#3E2A1A', fontSize: "2.92vw", fontWeight: '600', fontFamily: 'poppinsSemiBold' }}>Enjoy 4X4 Self-Drive with iNDiE GPS Guide</div>
            <div style={{ color: '#3E2A1A', fontSize: "1.5vw", fontWeight: '600', fontFamily: 'interSemiBold' }}>Self-guided Packages for the Independent Explorers</div>
          </>
          :
          <div style={{padding:'0vw 5vw'}}>
            <div style={{ color: '#3E2A1A', fontSize: "6vw", fontWeight: '600', fontFamily: 'poppinsSemiBold',textAlign:'center' }}>Enjoy 4X4 Self-Drive with iNDiE GPS Guide</div>
            <div style={{ color: '#3E2A1A', fontSize: "3.5vw", fontWeight: '600', fontFamily: 'interSemiBold',textAlign:'center',marginTop:'1vw' }}>Self-guided Packages for the Independent Explorers</div>
          </div>
        }
      </div>
      <animated.div style={{...animation}} >
      <DestinationsSection />
      </animated.div>
      <MostPopular pageFrom={'landing'} />
      <IndieGuide />
      <Headline />
      <TravelWithIndie />
      <Care />
      <LocalExpert />
      <animated.div style={{...animationFlip}} >
      <ShareYourDetails />
      </animated.div>
<Footer/>
    </div>
  )
}
export default LandingPage;
